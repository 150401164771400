import settings from '../../settings';
import { OperationDefinitionNode } from 'graphql';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client';

const wsLink = new WebSocketLink({
    uri: settings.env.REACT_APP_PUBSUB_BASE_URI || "",
    options: {
        reconnect: true,
        connectionParams: {
            Authorization: localStorage.getItem("@metacommerce-user")
        }
    }
});

const httpLink = createHttpLink({
    uri: settings.env.REACT_APP_GRAPHQL_BASE_URI,
});

const authLink = setContext((_, { headers }) => {
    return { headers: { ...headers } }
});

const link = split(
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
        return (
            kind === 'OperationDefinition' &&
            operation === 'subscription'
        );
    },
    wsLink,
    authLink.concat(httpLink)
);

const cache = new InMemoryCache({
    typePolicies: {
        Product: {
            fields: {
                operationalInfo: { merge: false },
            },
        },
    },
});

const UhmpcApiClient = new ApolloClient({
    link,
    cache,
});

export default UhmpcApiClient;