export class OrderShippingInformation {
    public carrierCode = '';
    public carrierTitle = '';
    public methodCode = '';
    public methodTitle = '';
    public price = 0;
    public tax = 0

    constructor (data: any = {}) {
      if (data !== null) {
        this.carrierCode = data.carrierCode
        this.carrierTitle = data.carrierTitle
        this.methodCode = data.methodCode
        this.methodTitle = data.methodTitle
        this.price = data.price
        this.tax = data.tax
      }
    }
}
