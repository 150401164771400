export class Attribute {
    public id = '';
    public name = '';
    public code = '';
    public type = '';
    public isUnique = false;
    public defaultValue = '';
    public isRequired = false;
    public values: string[] = [];

    constructor(data: any = {}) {
        if (data === null) 
            return ;

        this.id = data.id;
        this.name = data.name;
        this.code = data.code;
        this.type = data.type;
        this.isUnique = data.isUnique;
        this.isRequired = data.isRequired;
        this.defaultValue = data.defaultValue;

        if (data.values) {
            for (const value of data.values) {
                this.values.push(value);
            }
        }
    }
}
