export class CartBusinessInfo {
    public glCode = "";
    public location = "";
    public referenceNumber = "";

    constructor(data: any = {}) {
        this.glCode = data.glCode;
        this.location = data.location;
        this.referenceNumber = data.referenceNumber;
    }
}