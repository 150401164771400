import { gql } from '@apollo/client';
import ErrorHandlingService from '../services/ErrorHandlingService';
import { MyProductList } from '../models/MyProductList/MyProductList';
import CodApiClient from './graphql-clients/CodApiClient';

export default class MyProductListRepository {
    static async search(types: string[], status: string[], token: string): Promise<MyProductList[]> {
        if (!token)
            throw ErrorHandlingService.createError('INVALID_TOKEN', 'Invalid token');

        const variables: any = {};
        if (types.length > 0) {
            variables.types = types;
        }

        if (status.length > 0) {
            variables.status = status;
        }

        const query = gql`
            query myProductList ($types: [String], $status: [String]) {
                myProductList (types: $types, status: $status) {
                    id
                    name
                    type
                    status
                    createdAt
                    updatedAt
                    items {
                        id
                        productId
                        qty                        
                    }
                }
            }
        `;

        const context = {
            headers: { "Authorization": token }
        };

        const data: any = await CodApiClient
            .query({
                query,
                context,
                variables,
                fetchPolicy: "network-only"
            })
            .then(result => result.data && result.data.myProductList);

        return data.map((list: any) => new MyProductList(list));
    }

    static async get(token: string, id = ''): Promise<MyProductList[]> {
        if (!token)
            throw ErrorHandlingService.createError('INVALID_TOKEN', 'Invalid token');

        const variables: any = {};
        if (id !== '') {
            variables.id = id;
        }

        const query = gql`
            query myProductList ($id: String) {
                myProductList (id: $id) {
                    id
                    name
                    type
                    status
                    createdAt
                    updatedAt
                    items {
                        id
                        productId
                        qty
                        product {
                            id
                            sku
                            title
                            operationalInfo {
                                availability
                                price {
                                    price
                                    specialPrice
                                }
                            }
                            imageRefs
                            images {
                                gallery
                            }
                        }
                    }
                }
            }
        `;

        const context = {
            headers: { "Authorization": token }
        };

        const data: any = await CodApiClient
            .query({
                query,
                context,
                variables,
                fetchPolicy: "network-only"
            })
            .then(result => result.data && result.data.myProductList);

        return data.map((list: any) => new MyProductList(list));
    }

    static async createUpdate(token: string, productList: MyProductList): Promise<MyProductList> {
        const mutation = gql`
            mutation createUpdateMyProductList ($myProductList: MyProductListInputType!) {
                createUpdateMyProductList (myProductList: $myProductList) {
                    id
                    name
                    type
                    status
                    createdAt
                    updatedAt
                }
            }
        `;

        const variables: any = {
            myProductList: {
                id: productList.id,
                name: productList.name,
                type: productList.type,
                status: productList.status
            }
        }

        const context = {
            headers: { "Authorization": token }
        };

        const data = await CodApiClient
            .mutate({ mutation, variables, context })
            .then(result => result.data);

        return new MyProductList(data.createUpdateMyProductList);
    }

    static async createUpdateItems(token: string, productId: string, qty: number, myProductList: MyProductList): Promise<MyProductList> {
        const mutation = gql`
            mutation createUpdateMyProductListItems($id: String!, $items: [MyProductListItemInputType!]!) {
                createUpdateMyProductListItems (id: $id, items: $items) {
                    id
                    name 
                    type
                    status
                    createdAt
                    updatedAt
                    items {
                        id
                        productId
                        qty
                        product {
                            id
                            sku
                            title
                            operationalInfo {
                                availability
                                price {
                                    price
                                    specialPrice
                                }
                            }
                            imageRefs
                            images {
                                gallery
                            }
                        }
                    }
                }
            }
        `;

        const variables: any = {
            id: myProductList.id,
            items: [{
                productId: productId,
                qty: qty
            }]
        }

        const context = {
            headers: { "Authorization": token }
        };

        const data = await CodApiClient
            .mutate({ mutation, variables, context })
            .then(result => result.data);

        return new MyProductList(data.createUpdateMyProductListItems);
    }

    static async removeItems(
        token: string,
        productIds: string[],
        myProductList: MyProductList
    ): Promise<MyProductList> {
        const mutation = gql`
            mutation removeMyProductListItems($id: String!, $items: [String!]!) {
                removeMyProductListItems (id: $id, items: $items) {
                    id
                    name
                    type
                    status
                    createdAt
                    updatedAt
                    items {
                        id
                        productId                        
                        qty
                        product {
                            id
                            sku
                            title
                            operationalInfo {
                                availability
                                price {
                                    price
                                    specialPrice
                                }
                            }
                            imageRefs
                            images {
                                gallery
                            }
                        }
                    }
                }
            }
        `;

        const variables: any = {
            id: myProductList.id,
            items: productIds
        }

        const context = {
            headers: { "Authorization": token }
        };

        const data = await CodApiClient
            .mutate({ mutation, variables, context })
            .then(result => result.data);

        return new MyProductList(data.removeMyProductListItems);
    }

    static async addItemsToCart(token: string, cartToken: string, myProductList: MyProductList): Promise<boolean> {
        const mutation = gql`
            mutation addMyProductListItemsToCart($cartId: String!, $id: String!) {
                addMyProductListItemsToCart (cartId: $cartId, id: $id) 
            }
        `;

        const variables: any = {
            id: myProductList.id,
            cartId: cartToken
        }

        const context = {
            headers: { "Authorization": token }
        };

        const data = await CodApiClient
            .mutate({ mutation, variables, context })
            .then(result => result.data);

        return data.addMyProductListItemsToCart === true;
    }

    static async remove(myProductListId: string, token: string): Promise<boolean> {
        const mutation = gql`
            mutation removeMyProductList($id: String!) {
                removeMyProductList (id: $id) 
            }
        `;

        const variables: any = {
            id: myProductListId
        }

        const context = {
            headers: { "Authorization": token }
        };

        return await CodApiClient
            .mutate({ mutation, variables, context })
            .then(result => result.data);
    }

}