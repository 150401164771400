
export class OrderBusinessInfo {
    public poNumber = '';
    public glCode = '';
    public location = '';

    constructor(data: any = {}) {
        this.poNumber = data.poNumber;
        this.glCode = data.glCode;
        this.location = data.location;
    }
}
