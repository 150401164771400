export class Provider {
    public id = '';
    public kind = '';
    public displayName = '';
    public sites: string[] = [];

    constructor(data:any = null) {
        this.id = data.id;
        this.kind = data.kind;
        this.displayName = data.displayName;

        data.sites && 
        data.sites.forEach((site:string) => this.sites.push(site));
    }
}