export const formatDate = (date: Date) => {
  return new Intl.DateTimeFormat(undefined, { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit', 
      timeZoneName: 'short' 
  }).format(date);
};
