import { Address } from "../Address/Address";
import { OrderShippingInformation } from "./OrderShippingInformation";

export class OrderShipping {
    public info: OrderShippingInformation = new OrderShippingInformation();
    public address: Address = new Address();

    constructor (data: any = {}) {
        if (data.shippingInformation) {
            this.info = new OrderShippingInformation(data.shippingInformation);
        }
        if (data.address) {
            this.address = new Address(data.address);
        }
    }
}