import React, { useEffect, useState } from 'react';
import settings from './settings';
import Router from './pages/router';
import TagManager from 'react-gtm-module';
import ZohoSalesIQChat from './components/widgets/zoho-salesiq-chat';

import './styles.scss';
import UserStorage from './utils/userStorage';
import UserService from './services/UserService';
import Loader from './components/form/loader';

export default function App() {
    const [loading, setLoading] = useState<boolean>(true);
    if (settings.env.REACT_APP_GTM_CODE) {
        const tagManagerArgs = { gtmId: settings.env.REACT_APP_GTM_CODE };
        TagManager.initialize(tagManagerArgs);
    }

    if (settings.env.REACT_APP_SALES_IQ_CHAT_ID) {
        ZohoSalesIQChat(settings.env.REACT_APP_SALES_IQ_CHAT_ID);
    }

    useEffect(() => {
        if (UserStorage.getToken()) {
            UserService.getByToken()
                .then((_) => setLoading(false));
        } else {
            setLoading(false)
        }
    }, [])

    return (
        <div className="App">
            {loading ? <Loader /> :
                <>
                    <Router />
                </>
            }
        </div>
    );
}