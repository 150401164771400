export class ProductAttribute {
    public name = "";
    public value: any;
    public type: string;

    constructor(data: any = {}) {
        this.name = data.name;
        this.type = data.value?.__typename || data.type;

        if (this.type === "StringValue")
            this.value = data.value.string;
        else if (this.type === "ListValue")
            this.value = data.value.list;
        else if (this.type === "Html")
            this.value = data.value.html;
        else
            this.value = data.value;
    }
}