import React, { useEffect, useState } from 'react';
import settings from '../../settings';
import { Link, useHistory } from 'react-router-dom';
import SubmitButton from '../form/submit-button';
import { useCartToast } from '../../stores/cartToastStore';

import notFoundImage from '../../assets/images/no-image.jpg';
import './styles.scss';

interface PropTypes {
    id: string;
    title?: string;
    price?: string;
    productId: string;
    quantity?: number;
    imageUrl?: string;
}

export default function CartToastItem(props: PropTypes) {
    const history = useHistory();
    const toast = useCartToast();
    const [isClosing, setIsClosing] = useState(false);

    const onClose = () => {
        setIsClosing(true);
        // Wait for animation to complete before actually closing
        setTimeout(() => {
            toast.close(props.id);
        }, 600); // Total animation duration
    };

    const getImageUrl = (): string => {
        if (!props.imageUrl) return notFoundImage;
        return `${settings.env.REACT_APP_IMAGE_BASE_URI}/${props.imageUrl}`;
    };

    // Auto-close after 8 seconds
    useEffect(() => {
        const timer = setTimeout(onClose, 8000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div id={props.id} className={`cart-toast-item${isClosing ? ' closing' : ''}`}>
            <div className="header">
                <span>Just Added to Cart</span>
                <button className="close-btn" onClick={onClose}>X</button>
            </div>
            <div className="content">
                <div className="main-info">
                    <Link className="image-container" to={`/${props.productId}`}>
                        <img
                            alt={props.title}
                            src={getImageUrl()}
                            onError={(e: React.SyntheticEvent<HTMLImageElement>) =>
                                e.currentTarget.src = notFoundImage
                            }
                        />
                    </Link>
                    <div className="info">
                        <Link to={`/${props.productId}`} className="title-container">
                            <div className="title">
                                {props.title}
                            </div>
                        </Link>
                        <div className="quantity">
                            <span>Qty: </span>
                            <span>{props.quantity || 1}</span>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="price">{props.price}</div>
                    <SubmitButton
                        label="View Cart"
                        customClass="btn-view-cart"
                        onClick={() => history.push("/cart")}
                    />
                </div>
            </div>
        </div>
    );
};