import { Cart } from '../models';
import { gql } from '@apollo/client';
import cartClient from './graphql-clients/CartApiClient';
import ErrorHandlingService from '../services/ErrorHandlingService';

export default class CartRepository {
    static async create(customerToken?: string): Promise<string> {
        const mutation = gql`
            mutation {
                createCart
            }
        `;

        var headers = {};
        if (customerToken)
            headers = { "Authorization": customerToken };

        const cartToken = await cartClient
            .mutate({
                mutation,
                context: { headers }
            })
            .then((result: any) => {
                if (result?.data?.createCart) {
                    return result.data.createCart;
                } else {
                    throw ErrorHandlingService.createError(
                        'COULD_NOT_CREATE_CART',
                        'Could not create cart'
                    );
                }
            });

        return cartToken;
    }

    static async get(cartId: string, forceUpdate = false, customerToken?: string): Promise<Cart> {
        const query = gql`
            query cart ($cartId:String!, $forceUpdate: Boolean) {
                cart (cartId: $cartId, forceUpdate: $forceUpdate) {
                    id
                    type
                    email
                    glCode
                    location
                    isActive
                    referenceNumber
                    shipping {
                        shippingInformation {
                            carrierCode
                        }
                    }
                    items {
                        id
                        sku
                        qty
                        name
                        price
                        total
                        image
                    }
                    itemDiffs {
                        action
                        oldPrice
                        newPrice
                        item {
                            sku
                            name
                            image
                        }
                    }
                    coupons {
                        code
                    }
                    totals {
                        tax
                        grand
                        subtotal
                        shippingGrand
                        discount
                    }
                }
            }
        `;

        var headers = {};
        if (customerToken)
            headers = { "Authorization": customerToken };

        const variables = { cartId, forceUpdate };

        return await cartClient
            .query({
                query,
                variables,
                fetchPolicy: "network-only",
                context: { headers }
            })
            .then(async (result: any) => {
                if (result?.data?.cart) {
                    var cartData = result.data.cart;
                    const cart = new Cart(cartData);

                    if (cartId)
                        cart.setToken(cartId);

                    return cart;
                } else {
                    throw ErrorHandlingService.createError(
                        'COULD_NOT_GET_CART',
                        'Could not get cart: ' + cartId
                    );
                }
            });
    }

    static async addUpdateItem(
        productId: string,
        quantity: number | undefined,
        increment: boolean,
        cartToken: string,
        userToken?: string
    ): Promise<Cart> {
        const mutation = gql`
            mutation addUpdateCartItem(
                $cartId:String!,
                $item:InputAddUpdateCartItem!,
                $increment:Boolean
            ) {
                cart: addUpdateCartItem(
                    cartId: $cartId, 
                    item: $item,
                    increment: $increment
                ) {
                    id
                    glCode
                    location
                    isActive
                    referenceNumber
                    shipping {
                        shippingInformation {
                            carrierCode
                        }
                    }
                    items {
                        id
                        sku
                        qty
                        name
                        price
                        total
                        image
                    }
                    coupons {
                        code
                    }
                    totals {
                        tax
                        grand
                        subtotal
                        shippingGrand
                        discount
                    }
                }
            }
        `;

        var headers = {};
        if (userToken)
            headers = { 'Authorization': userToken };

        const variables = {
            increment,
            cartId: cartToken,
            item: {
                sku: productId,
                qty: quantity
            }
        };

        return await cartClient
            .mutate({
                mutation,
                variables,
                context: { headers }
            })
            .then(async (result: any) => {
                if (result?.data?.cart) {
                    var cartData = result.data.cart;
                    return new Cart(cartData);
                } else {
                    throw ErrorHandlingService.createError(
                        'COULD_NOT_ADD_PRODUCT',
                        `Could not add [${productId}] to cart [${cartToken}]`
                    );
                }
            });
    }

    static async transferCartFromGuestToCustomer(
        cartId: string,
        token: string
    ): Promise<string> {
        const mutation = gql`
            mutation transferCart($cartId: String!) {
                transferCart(cartId: $cartId) {
                    id
                }
            }
        `;

        var headers = {};
        if (token)
            headers = { 'Authorization': token };

        const variables = { cartId };

        const cartToken = await cartClient
            .mutate({
                mutation,
                variables,
                context: { headers }
            })
            .then((result: any) => {
                if (result?.data?.transferCart) {
                    return result.data.transferCart.id
                } else {
                    throw ErrorHandlingService.createError(
                        'COULD_NOT_TRANSFER_CART_FROM_GUEST_TO_CUSTOMER',
                        'Could not transfer cart from guest to customer'
                    );
                }
            });

        return cartToken;
    }

    static async clearCart(
        cartToken: string,
        customerToken?: string
    ): Promise<Cart> {
        const mutation = gql`
            mutation deleteCartAllItems($cartId: String!) {
                cart: deleteCartAllItems(cartId: $cartId) {
                    id
                    glCode
                    location
                    isActive
                    referenceNumber
                    shipping {
                        shippingInformation {
                            carrierCode
                        }
                    }
                    items {
                        id
                        sku
                        qty
                        name
                        price
                        total
                        image
                    }
                    coupons {
                        code
                    }
                    totals {
                        tax
                        grand
                        subtotal
                        shippingGrand
                        discount
                    }
                }
            }
        `;

        let headers = {};
        if (customerToken)
            headers = { "Authorization": customerToken };

        const variables = { cartId: cartToken };

        return await cartClient
            .mutate({
                mutation,
                variables,
                context: { headers }
            })
            .then(async (result: any) => {
                if (result?.data?.cart) {
                    var cartData = result.data.cart;
                    return new Cart(cartData);
                } else {
                    throw ErrorHandlingService.createError(
                        'COULD_NOT_REMOVE_ALL_ITEMS_FROM_CART',
                        `Could not remove all items from cart [${cartToken}]`
                    );
                }
            });
    }

    static async setBusinessInfo(
        key: string,
        value: string,
        cartToken: string,
        customerToken?: string
    ): Promise<boolean> {
        const mutation = gql`
            mutation setBusinessInfo (
                $cartId: String!,
                $fieldName: String!,
                $value: String!
            ) {
                result: setBusinessInfo (
                    value: $value,
                    cartId: $cartId, 
                    fieldName: $fieldName,
                )
            }
        `;

        let headers = {};
        if (customerToken)
            headers = { "Authorization": customerToken };

        const variables = {
            value,
            fieldName: key,
            cartId: cartToken,
        };

        return await cartClient
            .mutate({
                mutation,
                variables,
                context: { headers }
            })
            .then(async (result: any) => {
                if (result?.data?.result) {
                    return result.data.result;
                } else {
                    throw ErrorHandlingService.createError(
                        'COULD_NOT_SET_BUSINESS_INFO',
                        `Could not set #[${key}, ${value}] to cart [${cartToken}]`
                    );
                }
            });
    }

    static async setProductListId (
        productListId: string,
        cartToken: string,
        customerToken?: string
    ): Promise<boolean> {
        const mutation = gql`
            mutation setProductList ($cartId: String!, $productListId: String!) {
                result: setProductList (
                    cartId: $cartId, 
                    productListId: $productListId
                )
            }
        `;

        let headers = {};
        if (customerToken)
            headers = { "Authorization": customerToken };

        const variables = {
            cartId: cartToken,
            productListId
        };

        return await cartClient
            .mutate({
                mutation,
                variables,
                context: { headers }
            })
            .then(async (result: any) => {
                if (result?.data?.result) {
                    return result.data.result;
                } else {
                    throw ErrorHandlingService.createError(
                        'COULD_NOT_SET_PRODUCT_LIST_ID',
                        `Could not set product list #[${productListId}] to cart [${cartToken}]`
                    );
                }
            });
    }

    static async addCoupon(
        code: string,
        cartToken: string,
        customerToken?: string
    ): Promise<Cart> {
        const mutation = gql`
            mutation addCouponToCart ($cartId: String!, $coupon: String!) {
                cart: addCouponToCart (cartId: $cartId, coupon: $coupon) {
                    id
                    glCode
                    location
                    isActive
                    referenceNumber
                    shipping {
                        shippingInformation {
                            carrierCode
                        }
                    }
                    items {
                        id
                        sku
                        qty
                        name
                        price
                        total
                        image
                    }
                    coupons {
                        code
                    }
                    totals {
                        tax
                        grand
                        subtotal
                        shippingGrand
                        discount
                    }
                }
            }
        `;

        let headers = {};
        if (customerToken)
            headers = { "Authorization": customerToken };

        const variables = {
            cartId: cartToken,
            coupon: code
        };

        return await cartClient
            .mutate({
                mutation,
                variables,
                context: { headers }
            })
            .then(async (result: any) => {
                if (result?.data?.cart) {
                    var cartData = result.data.cart;
                    return new Cart(cartData);
                } else {
                    throw ErrorHandlingService.createError(
                        'COULD_NOT_APPLY_DISCOUNT',
                        `Could not apply code [${code}] to cart [${cartToken}]`
                    );
                }
            });
    }

    static async deleteCoupons(
        cartToken: string,
        couponCodes: string[],
        customerToken?: string
    ): Promise<Cart> {
        const mutation = gql`
            mutation deleteCouponsFromCart ($cartId: String!, $coupons: [String!]!) {
                cart: deleteCouponsFromCart(cartId: $cartId, coupons: $coupons) {
                    id
                    glCode
                    location
                    isActive
                    referenceNumber
                    shipping {
                        shippingInformation {
                            carrierCode
                        }
                    }
                    items {
                        id
                        sku
                        qty
                        name
                        price
                        total
                        image
                    }
                    coupons {
                        code
                    }
                    totals {
                        tax
                        grand
                        subtotal
                        shippingGrand
                        discount
                    }
                }
            }
        `;

        let headers = {};
        if (customerToken)
            headers = { "Authorization": customerToken };

        const variables = { cartId: cartToken, coupons: couponCodes };

        return await cartClient
            .mutate({
                mutation,
                variables,
                context: { headers }
            })
            .then(async (result: any) => {
                if (result?.data?.cart) {
                    var cartData = result.data.cart;
                    return new Cart(cartData);
                } else {
                    throw ErrorHandlingService.createError(
                        'COULD_NOT_REMOVE_DISCOUNT',
                        `Could not remove coupon from cart [${cartToken}]`
                    );
                }
            });
    }
}