import MyProductListRepository from '../repositories/MyProductListRepository';
import UserStorage from '../utils/userStorage';
import ErrorHandlingService from './ErrorHandlingService';
import UserService from './UserService';
import { accessMyProductLists } from '../stores/myProductListStore';
import { MyProductList } from '../models';
import CartStorage from '../utils/cartStorage';

class MyProductListService {
    static async search(types: string[], status: string[]): Promise<MyProductList[]> {
        const userToken = UserStorage.getToken();
        try {
            const myProductLists = await MyProductListRepository
                .search(types, status, userToken);
            return myProductLists;
        } catch (e) {
            const messages = ErrorHandlingService.getMessages(e);
            if (messages.some(m => m.code === "CUSTOMER_NOT_FOUND"))
                UserService.logout();

            throw messages;
        }
    }

    static async list(): Promise<MyProductList[]> {
        const userToken = UserStorage.getToken();
        try {
            const myProductLists = await MyProductListRepository.get(userToken);
            accessMyProductLists().set(myProductLists);

            return myProductLists;
        } catch (e) {
            const messages = ErrorHandlingService.getMessages(e);
            if (messages.some(m => m.code === "CUSTOMER_NOT_FOUND"))
                UserService.logout();

            throw messages;
        }
    }

    static async get(id: string): Promise<MyProductList | null> {
        const userToken = UserStorage.getToken();
        try {
            const myProductLists = await MyProductListRepository.get(userToken, id);
            if (myProductLists.length <= 0)
                return null;

            accessMyProductLists().setList(myProductLists[0]);
            return myProductLists[0];
        } catch (e) {
            const messages = ErrorHandlingService.getMessages(e);
            if (messages.some(m => m.code === "CUSTOMER_NOT_FOUND"))
                UserService.logout();

            throw messages;
        }
    }

    static async createUpdate(
        myProductList: MyProductList
    ): Promise<MyProductList> {
        const token = UserStorage.getToken();
        try {
            const newMyProductList = await MyProductListRepository
                .createUpdate(token, myProductList);
            accessMyProductLists().setList(myProductList.id 
                ? myProductList 
                : newMyProductList
            );
            return newMyProductList;
        } catch (e) {
            const messages = ErrorHandlingService.getMessages(e);
            if (messages.some(m => m.code === "CUSTOMER_NOT_FOUND"))
                UserService.logout();

            throw messages;
        }
    }

    static async createUpdateItems(
        productId: string,
        qty: number,
        myProductList: MyProductList
    ): Promise<MyProductList> {
        const token = UserStorage.getToken();
        try {
            const result = qty > 0
                ? await MyProductListRepository
                    .createUpdateItems(
                        token, productId, qty, myProductList
                    )
                : await MyProductListRepository
                    .removeItems(
                        token, [productId], myProductList
                    );

            const updatedList = Object.assign({}, myProductList);
            updatedList.items = result.items;
            accessMyProductLists().setList(updatedList);
            return updatedList;
        } catch (e) {
            const messages = ErrorHandlingService.getMessages(e);
            if (messages.some(m => m.code === "CUSTOMER_NOT_FOUND"))
                UserService.logout();

            throw messages;
        }
    }

    static async removeItems(
        productId: string,
        myProductList: MyProductList
    ): Promise<MyProductList> {
        const token = UserStorage.getToken();
        try {
            const result = await MyProductListRepository
                .removeItems(token, [productId], myProductList);
                
            const updatedList = Object.assign({}, myProductList);
            updatedList.items = result.items;
            accessMyProductLists().setList(updatedList);
            return updatedList;
        } catch (e) {
            const messages = ErrorHandlingService.getMessages(e);
            if (messages.some(m => m.code === "CUSTOMER_NOT_FOUND"))
                UserService.logout();

            throw messages;
        }
    }

    static async addItemsToCart(myProductList: MyProductList): Promise<boolean> {
        const token = UserStorage.getToken();
        try {
            const cartToken = CartStorage.getToken() || '';
            return await MyProductListRepository
                .addItemsToCart(token, cartToken, myProductList);
        } catch (e) {
            const messages = ErrorHandlingService.getMessages(e);
            if (messages.some(m => m.code === "CUSTOMER_NOT_FOUND"))
                UserService.logout();

            throw messages;
        }
    }

    static async remove(myProductListId: string): Promise<boolean> {
        const token = UserStorage.getToken();
        try {
            const result = 
                await MyProductListRepository.remove(myProductListId, token);
            if (result)
                accessMyProductLists().removeById(myProductListId);
                
            return result;
        } catch (e) {
            const messages = ErrorHandlingService.getMessages(e);
            if (messages.some(m => m.code === "CUSTOMER_NOT_FOUND"))
                UserService.logout();

            throw messages;
        }
    }
}

export default MyProductListService;
