import { CreditCard } from "./CreditCard";

export class CustomerPaymentToken {
    public id: string;
    public type: string;
    public details: any;
    public createdAt: Date;
    public expiresAt: Date;
    public isActive: boolean;
    public customerId: number;
    public publicHash: string;
    public isVisible: boolean;
    public gatewayToken: string;
    public paymentMethodCode: string;

    constructor(data: any) {
        this.id = data.id;
        this.customerId = data.customerId;
        this.publicHash = data.publicHash;
        this.paymentMethodCode = data.paymentMethodCode;
        this.type = data.type;
        this.createdAt = data.createdAt;
        this.expiresAt = data.expiresAt;
        this.gatewayToken = data.gatewayToken;
        this.isActive = data.isActive;
        this.isVisible = data.isVisible;
        
        if (data.details) {
            const parsedDetails = JSON.parse(data.details);
            if (this.type === "card")
                this.details = new CreditCard(parsedDetails);
            else
                this.details = parsedDetails;
        }
    }
}