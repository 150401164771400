export class BlogPost {
    public id = 0
    public urlKey = ''
    public title = ''
    public shortContent = ''
    public fullContent = ''
    public metaTitle = ''
    public metaTags = ''
    public metaDescription = ''
    public createdAt: Date
    public updatedAt: Date
    public publishedAt: Date

    constructor(data: any = {}) {
        this.id = data.id;
        this.urlKey = data.urlKey;
        this.title = data.title;
        this.shortContent = data.shortContent;
        this.fullContent = data.fullContent;
        this.metaTitle = data.metaTitle;
        this.metaTags = data.metaTags;
        this.metaDescription = data.metaDescription;
        this.createdAt = new Date(parseInt(data.createdAt));
        this.updatedAt = new Date(parseInt(data.updatedAt));
        this.publishedAt = new Date(parseInt(data.publishedAt));
    }
}