import { Parameter } from "./Parameter";

export class ParameterCategory {
    public static createArray(data:any):Parameter[] {
        let categories = new Array<Parameter>();
        data.forEach((parameter:any) =>
            categories.push(new Parameter(parameter.value, parameter.count))
        );

        return categories;
    }
}