import { MyProductListItem } from "./MyProductListItem";

export class MyProductList {
    public id?: string;
    public name: string;
    public slug: string;
    public type: string;
    public status: string;
    public customerId: number;
    public createdAt = new Date();
    public updatedAt = new Date();
    public items: MyProductListItem[] = [];

    constructor(data: any = {}) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.customerId = data.customerId;
        this.createdAt = data.createdAt instanceof Date 
            ? data.createdAt
            : new Date(data.createdAt);
        this.updatedAt = data.updatedAt instanceof Date 
            ? data.updatedAt
            : new Date(data.updatedAt);

        this.type = data.type || 'shopping';
        this.status = data.status || 'open';

        data.items?.forEach((item: any) => 
            this.items.push(new MyProductListItem(item))
        );
    }
}