if ((window as any).env === undefined) {
    (window as any).env = {}
}

const settings = {
    env: {
        REACT_APP_COMPANY_NAME: (window as any).env.REACT_APP_COMPANY_NAME ?? process.env.REACT_APP_COMPANY_NAME,
        REACT_APP_GRAPHQL_BASE_URI: (window as any).env.REACT_APP_GRAPHQL_BASE_URI ?? process.env.REACT_APP_GRAPHQL_BASE_URI,
        REACT_APP_IMAGE_BASE_URI: (window as any).env.REACT_APP_IMAGE_BASE_URI ?? process.env.REACT_APP_IMAGE_BASE_URI,
        REACT_APP_GATEWAY_URI: (window as any).env.REACT_APP_GATEWAY_URI ?? process.env.REACT_APP_GATEWAY_URI,
        REACT_APP_BRAINTREE_GRAPHQL_BASE_URI: (window as any).env.REACT_APP_BRAINTREE_GRAPHQL_BASE_URI ?? process.env.REACT_APP_BRAINTREE_GRAPHQL_BASE_URI,
        REACT_APP_THEME: (window as any).env.REACT_APP_THEME ?? process.env.REACT_APP_THEME,
        REACT_APP_MAGENTO_IMAGE_BASE_URI: (window as any).env.REACT_APP_MAGENTO_IMAGE_BASE_URI ?? process.env.REACT_APP_MAGENTO_IMAGE_BASE_URI,

        REACT_APP_GTM_CODE: (window as any).env.REACT_APP_GTM_CODE ?? process.env.REACT_APP_GTM_CODE,
        REACT_APP_SALES_IQ_CHAT_ID: (window as any).env.REACT_APP_SALES_IQ_CHAT_ID ?? process.env.REACT_APP_SALES_IQ_CHAT_ID,
        REACT_APP_SHARE_A_SALE_MERCHANT_ID: (window as any).env.REACT_APP_SHARE_A_SALE_MERCHANT_ID ?? process.env.REACT_APP_SHARE_A_SALE_MERCHANT_ID,

        REACT_APP_ENVIRONMENT: (window as any).env.REACT_APP_ENVIRONMENT ?? process.env.REACT_APP_ENVIRONMENT,

        REACT_APP_COD_GRAPHQL_URL: (window as any).env.REACT_APP_COD_GRAPHQL_URL ?? process.env.REACT_APP_COD_GRAPHQL_URL,

        REACT_APP_DEFAULT_GROUP_CODE: (window as any).env.REACT_APP_DEFAULT_GROUP_CODE ?? process.env.REACT_APP_DEFAULT_GROUP_CODE,

        REACT_APP_PUBSUB_BASE_URI: (window as any).env.REACT_APP_PUBSUB_BASE_URI ?? process.env.REACT_APP_PUBSUB_BASE_URI,

        REACT_APP_PARAFLOW_GRAPHQL_URL: (window as any).env.REACT_APP_PARAFLOW_GRAPHQL_URL ?? process.env.REACT_APP_PARAFLOW_GRAPHQL_URL,
        REACT_APP_PARAFLOW_EVENTS_URL: (window as any).env.REACT_APP_PARAFLOW_GRAPHQL_URL ?? process.env.REACT_APP_PARAFLOW_GRAPHQL_URL,
        REACT_APP_PARAFLOW_OWE_GRAPHQL_URL: (window as any).env.REACT_APP_PARAFLOW_OWE_GRAPHQL_URL ?? process.env.REACT_APP_PARAFLOW_OWE_GRAPHQL_URL,
        
        REACT_APP_CART_GRAPHQL_BASE_URI: (window as any).env.REACT_APP_CART_GRAPHQL_BASE_URI ?? process.env.REACT_APP_CART_GRAPHQL_BASE_URI,

        REACT_APP_CMS_API: (window as any).env.REACT_APP_CMS_API ?? process.env.REACT_APP_CMS_API,
        REACT_APP_CMS_IMAGE_URI: (window as any).env.REACT_APP_CMS_IMAGE_URI ?? process.env.REACT_APP_CMS_IMAGE_URI,

        REACT_APP_GOOGLE_RECAPTCHA_TOKEN: (window as any).env.REACT_APP_GOOGLE_RECAPTCHA_TOKEN ?? process.env.REACT_APP_GOOGLE_RECAPTCHA_TOKEN,

    }
};
  
export default settings;


