export interface ErrorMessage {
    code: string;
    message: string;
}

export default class ErrorHandlingService {
    public static getMessages (error: any):ErrorMessage[] {
        const messages: ErrorMessage[] = [];
        
        var messagesArray:any = null;
        if (error?.networkError && error.networkError?.result && error.networkError.result?.errors)
            messagesArray = error.networkError.result.errors;
        else if (error?.graphQLErrors && error.graphQLErrors[0]?.message)
            messagesArray = error.graphQLErrors[0].message;
        else if (error?.message)
            messagesArray = error.message;

        if (messagesArray) {
            messagesArray.forEach ? 
                messagesArray.forEach((m:any) => {
                    if (m.message?.forEach)
                        m.message.forEach((mm:any) => messages.push(mm));
                    else
                        messages.push(m);
                }) 
                :
                messages.push({
                    code: "NETWORK_ERROR",
                    message: messagesArray
                });
        } else {
            messages.push({
                code: "UNKNOW_ERROR",
                message: "Error: something went wrong (error 99)"
            });
        }

        return messages;
    }

    public static getMessagesString(error: any, separator=''): string {
        const messages: ErrorMessage[] = ErrorHandlingService.getMessages(error);
        return ErrorHandlingService.toString(messages);
    }

    public static toString(messages: ErrorMessage[], separator=''): string {
        let messageStr = '';
        for (const message of messages) {
            messageStr += message.message + separator;
        }

        return messageStr;
    }

    public static createError(code: string, message: string) {
        return { message: [{ code, message }] };
    }
}