export class CartItem {
    public id = "";
    public price = 0;
    public total = 0;
    public name = "";
    public image = "";
    public quantity = 0;
    public grokitId = "";

    constructor(data: any = {}) {
        this.id = data.id;
        this.name = data.name;
        this.price = data.price;
        this.total = data.total;
        this.image = data.image;
        this.quantity = data.quantity;
        this.grokitId = data.grokitId || data.sku;
    }
}