
export class BlogCategory {
    public id = 0
    public urlKey = ''
    public name = ''
    public metaTitle = ''
    public metaTags = ''
    public metaDescription = ''

    constructor(data: any = {}) {
        this.id = data.id;
        this.urlKey = data.urlKey;
        this.name = data.name;
        this.metaTitle = data.metaTitle;
        this.metaTags = data.metaTags;
        this.metaDescription = data.metaDescription;
    }
}