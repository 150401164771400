import { Permission } from "./Permission";

export enum RoleType {
    Individual = "customer-simple",
    Company = "customer-company-admin",
    Buyer = "customer-company-buyer",
    Manager = "customer-company-manager",
}

export class Role {
    public id = '';
    public name = '';
    public level = 0;
    public permissions: Permission[] = [];
    public code:RoleType = RoleType.Individual;

    constructor(data: any = {}) {
        this.id = data.id;
        this.name = data.name;
        this.level = parseInt(data.level);
        switch (data.code) {
            case RoleType.Company:
                this.code = RoleType.Company;
                break;
            case RoleType.Individual:
                this.code = RoleType.Individual;
                break;
            case RoleType.Buyer:
                this.code = RoleType.Buyer;
                break;
            case RoleType.Manager:
                this.code = RoleType.Manager;
                break;
        }

        if (data.permissions) {
            for (const permissionData of data.permissions) {
                const permission = new Permission(permissionData);
                this.permissions.push(permission);
            }
        }
    }
}
