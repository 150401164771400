export class OrderHistory {
    public id = '';
    public createdAt: Date = new Date();
    public event = '';
    public message = '';
    public userId = '';
    public status = '';

    constructor (data: any = {}) {
        // console.log(data);
        this.id = data.id;
        this.createdAt = data.timestamp ? new Date(data.timestamp) : new Date();
        this.event = data.event;
        this.message = data.message;
        this.userId = data.userId;
        this.status = data.status;
    }

}