import settings from '../settings';
import TagManager from 'react-gtm-module';
import { Cart, Order, Product } from '../models';

export default class TagManagerService {
    static registerSearchResult(currentURL: string) {
        if (!settings.env.REACT_APP_GTM_CODE)
            return;

        TagManager.dataLayer({
            dataLayer: {
                event: "search",
                ecommerce: { search_term: currentURL }
            },
        })
    }

    static registerProductDetail(product: Product) {
        if (!settings.env.REACT_APP_GTM_CODE)
            return;

        const item = [{
            item_id: product.id,
            item_name: product.title,
            item_brand: product.source,
            item_category: product.categories?.join(", ") || "",
        }];

        TagManager.dataLayer({ dataLayer: { ecommerce: null } });
        TagManager.dataLayer({
            dataLayer: {
                event: "view_item",
                ecommerce: {
                    items: [item],
                    currency: "USD",
                }
            },
        })
    }

    static registerAddedToCart(product: Product, quantity: number) {
        if (!settings.env.REACT_APP_GTM_CODE)
            return;

        const item = [{
            quantity,
            item_id: product.id,
            item_name: product.title,
            item_brand: product.source,
            item_category: product.categories?.join(", ") || "",
        }];

        TagManager.dataLayer({ dataLayer: { ecommerce: null } });
        TagManager.dataLayer({
            dataLayer: {
                event: "add_to_cart",
                ecommerce: {
                    items: [item],
                    currency: "USD",
                }
            },
        })
    }

    static registerCheckoutStarted(cart: Cart) {
        if (!cart.items || !settings.env.REACT_APP_GTM_CODE)
            return;

        const items = cart.items.map(i => ({
            price: i.price,
            item_variant: "",
            item_name: i.name,
            item_id: i.grokitId,
            quantity: i.quantity,
            item_brand: "iremedy",
        }));

        TagManager.dataLayer({ dataLayer: { ecommerce: null } });
        TagManager.dataLayer({
            dataLayer: {
                event: "begin_checkout",
                ecommerce: {
                    items,
                    currency: "USD",
                },
            },
        })
    }

    static registerCheckoutCompleted(order: Order) {
        if (!settings.env.REACT_APP_GTM_CODE)
            return;

        const items = order.items.map(i => ({
            item_id: i.id,
            price: i.price,
            item_brand: "",
            item_variant: "",
            item_category: "",
            item_name: i.name,
            affiliation: "iremedy",
            quantity: i.qtyOrdered,
        }));

        TagManager.dataLayer({ dataLayer: { ecommerce: null } });
        TagManager.dataLayer({
            dataLayer: {
                event: "purchase",
                ecommerce: {
                    items,
                    currency: "USD",
                    tax: order.totals.tax,
                    grand: order.totals.grand,
                    shipping: order.totals.shipping,
                    transaction_id: order.originRef,
                },
            },
        });
    }
}