import React, { useEffect, useState } from 'react';
import MainTemplate from '../../../templates/main';
import Loader from '../../../../components/form/loader';
import { useFlash } from '../../../../stores/flashStore';
import InfoCard from '../../../../components/form/info-card';
import { ErrorMessage } from '../../../../services/ErrorHandlingService';
import AccountLeftMenu, { SubMenuType } from '../../../../components/account-left-menu';

import './styles.scss';
import UserService from '../../../../services/UserService';
import { User, UserAttribute } from '../../../../models';
import SubmitButton from '../../../../components/form/submit-button';
import Table from '../../../../components/form/table';
import Modal from '../../../../components/Modal';
import TextField from '../../../../components/form/textfield';
import Select from '../../../../components/form/select';
import { BuyerGroup } from '../../../../models/BuyerGroups/BuyerGroup';
import BuyerGroupsService from '../../../../services/BuyerGroupsService';
import { useHistory } from 'react-router-dom';

type TEditUserForm = {
    customerAccount: string;
    npi: string;
    stateLicense: string,
    buyerGroup: string, // id of buyer groups
}

export default function ManageCustomers() {
    const itemsPerPage = 10;
    const flash = useFlash();
    const history = useHistory();

    const defaultForm = {
        buyerGroup: '',
        customerAccount: '',
        npi: '',
        stateLicense: ''
    } as TEditUserForm;

    const [currentPage, setCurrentPage] = useState(0);
    const [morePages, setMorePages] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [users, setUsers] = useState<User[]>([]);
    const [loadingNext, setLoadingNext] = useState<boolean>(false);
    const [loadingPrev, setLoadingPrev] = useState<boolean>(false);
    const [buyerGroups, setBuyerGroups] = useState<BuyerGroup[]>([])
    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    // Edit user
    const [targetUser, setTargetUser] = useState<User | null>(null);
    const [editUserForm, setEditUserForm] = useState<TEditUserForm>(defaultForm);

    useEffect(() => {
        loadUsers(currentPage);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        BuyerGroupsService.list()
            .then(res => {
                console.log("res", res);
                setBuyerGroups(res)
            })
            .catch(errors => {
                flash.show({
                    type: "error",
                    message: errors.map((e: any) => e.message).join("\n")
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadUsers = async (
        page: number,
        loadingPage?: boolean,
    ) => {
        if (loadingPage)
            (page > currentPage ? setLoadingNext : setLoadingPrev)(true);
        else
            setLoading(true);
        const pagination = {
            page: page,
            limit: itemsPerPage,
        }
        await UserService.list("", pagination)
            .then(async (res) => {
                setUsers(res.users || []);
                setCurrentPage(page);
                setMorePages(page < res.pagination.totalPages);
            })
            .catch((errors: ErrorMessage[]) => {
                flash.show({
                    type: "error",
                    message: errors.map(e => e.message).join("\n")
                });
            })
            .finally(() => {
                setLoading(false);
                setLoadingNext(false);
                setLoadingPrev(false);
            });
    };

    const edituserModelContent = () => {

        const handleInputChange = (field: string, value: any) => {
            setEditUserForm(prev => ({ ...prev, [field]: value }));
        }

        const handleSave = () => {
            if (!targetUser) return;
            console.log('targetUser', targetUser)
            const userToSave: { id: string, userAttributes: UserAttribute[] } = {
                id: targetUser.id,
                userAttributes: []
            };
            const { buyerGroup, customerAccount, npi, stateLicense } = editUserForm;

            const updateOrAddAttribute = (attributeCode: string, attributeGroupCode: string, value: any) => {
                if (value === '') return;
                const existingAttribute = userToSave.userAttributes.find(ua => ua.attributeCode === attributeCode);
                if (existingAttribute) {
                    existingAttribute.value = value;
                } else {
                    userToSave.userAttributes.push(new UserAttribute({
                        attributeGroupCode,
                        attributeCode,
                        value
                    }));
                }
            };

            // Update or add attributes for each value
            updateOrAddAttribute("customer-account", "personal-information", customerAccount);
            updateOrAddAttribute("npi", "national-provider-identifier", npi);
            updateOrAddAttribute("state-license", "license", stateLicense);
            updateOrAddAttribute("buyer-group", "groups", buyerGroup);

            UserService.update(new User(userToSave))
                .then(updatedUser => {
                    setEditUserForm(defaultForm);
                    setTargetUser(null);
                    setUsers(prevUsers =>
                        prevUsers.map(user =>
                            user.id === updatedUser.id ? updatedUser : user
                        )
                    );
                    flash.show({
                        type: "success",
                        message: "User updated successfully"
                    });
                })
                .catch((errors) => {
                    setSaveLoading(false);
                    flash.show({
                        type: "error",
                        message: errors.map((e: any) => e.message).join("\n")
                    });
                });
        };


        return (
            <div className="edit-user-info-fields">
                <h2>Edit User Information</h2>
                <div>
                    <TextField
                        name="customerAccount"
                        label="Customer Account #"
                        value={editUserForm.customerAccount}
                        onChange={(value) =>
                            handleInputChange("customerAccount", value)
                        }
                    />
                    <TextField
                        name="npi"
                        label="NPI #"
                        value={editUserForm.npi}
                        onChange={(value) =>
                            handleInputChange("npi", value)
                        }
                    />
                    <TextField
                        name="stateLicense"
                        label="State License"
                        value={editUserForm.stateLicense}
                        onChange={(value) =>
                            handleInputChange("stateLicense", value)
                        }
                    />
                    <Select
                        required
                        label="Buyer Group"
                        selectedValue={editUserForm.buyerGroup}
                        options={[
                            { key: "", value: "Select an option" },
                            ...buyerGroups.map(buyerGroup => ({ key: JSON.stringify({ id: buyerGroup.id, name: buyerGroup.name }), value: buyerGroup.name }))
                        ]}
                        onChange={value =>
                            handleInputChange("buyerGroup", value)
                        }
                    />
                </div>
                <div className="edit-user-actions">
                    <SubmitButton label="Save" onClick={handleSave} disabled={saveLoading} />
                    <button
                        className="btn secondary"
                        onClick={() => {
                            setEditUserForm(defaultForm);
                            setTargetUser(null);
                        }}
                        disabled={saveLoading}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        )
    }

    return (
        <MainTemplate>
            <div className="manage-customers-container">
                <AccountLeftMenu
                    title="Manage Customers"
                    submenu={SubMenuType.ManageCustomers}
                    subtitle="Manage Customers"
                />

                <div className="details">
                    <div className="content">
                        <InfoCard title="Customers List">
                            {loading ?
                                <Loader />
                                :
                                <Table
                                    headers={[
                                        { label: "ID" },
                                        { label: "First Name" },
                                        { label: "Last Name" },
                                        { label: "Customer Account #" },
                                        { label: "NPI #" },
                                        { label: "State License #" },
                                        { label: "Phone Number" },
                                        { label: "Company Name" },
                                        { label: "Role" },
                                        { label: "Buyer Group" },
                                        { label: "Actions" },
                                    ]}
                                    footer={<>
                                        {(!users || users.length <= 0) &&
                                            <div className="not-found">No users found</div>
                                        }
                                        <div className="pager">
                                            Page #{currentPage + 1}
                                        </div>
                                        <div className="pager">
                                            <button
                                                className="load-more-btn"
                                                disabled={currentPage === 0}
                                                onClick={() => {
                                                    loadUsers(currentPage - 1, true);
                                                }}
                                            >
                                                {loadingPrev ?
                                                    <Loader inverted /> :
                                                    <span>Previous Page</span>
                                                }
                                            </button>
                                            <button
                                                disabled={
                                                    !morePages ||
                                                    (
                                                        !!users &&
                                                        users.length < itemsPerPage
                                                    )
                                                }
                                                className="load-more-btn"
                                                onClick={() => {
                                                    loadUsers(currentPage + 1, true);
                                                }}
                                            >
                                                {loadingNext ?
                                                    <Loader inverted /> :
                                                    <span>Next Page</span>
                                                }
                                            </button>
                                        </div>
                                    </>}
                                >
                                    {users && users.length > 0 && users.map((user: User) =>
                                        <tr key={user.id} className='row' onClick={() => history.push(`/my-account/manage-customers/customers/${user.id}`)}>
                                            <td>{user.id}</td>
                                            <td>{user.getAttributeValue("firstname") || '-'}</td>
                                            <td>{user.getAttributeValue("lastname") || '-'}</td>
                                            <td>{user.getAttributeValue("customer-account") || '-'}</td>
                                            <td>{user.getAttributeValue("npi") || '-'}</td>
                                            <td>{user.getAttributeValue("state-license") || '-'}</td>
                                            <td>{user.getAttributeValue("phone") || '-'}</td>
                                            <td>{user.getAttributeValue("company") || '-'}</td>
                                            <td>{user.role.name}</td>
                                            <td>
                                                {
                                                    typeof user.getAttributeValue("buyer-group") === 'string' ?
                                                        JSON.parse(user.getAttributeValue("buyer-group") || "{}")['name'] :
                                                        '-'
                                                }
                                            </td>
                                            <td className="actions">
                                                <SubmitButton
                                                    label="Edit"
                                                    customClass="btn"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setTargetUser(user)
                                                        setEditUserForm({
                                                            buyerGroup: user.getAttributeValue("buyer-group") || '',
                                                            customerAccount: user.getAttributeValue("customer-account") || '',
                                                            npi: user.getAttributeValue("npi") || "",
                                                            stateLicense: user.getAttributeValue("state-license") || ""
                                                        })
                                                    }}
                                                />
                                            </td>
                                        </tr>)}
                                </Table>
                            }
                        </InfoCard>
                    </div>
                </div>
                {targetUser &&
                    <Modal
                        content={edituserModelContent()}
                        onClose={() => {
                            setEditUserForm(defaultForm);
                            setTargetUser(null);
                        }}
                        open
                    />
                }
            </div>
        </MainTemplate >
    );
}