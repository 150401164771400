import { AttributeGroup, Attribute } from "../";

export interface UserAttributeDto {
    value: string;
    attributeCode: string;
}

export class UserAttributeList {
    public id: string = '';
    public items: UserAttribute[] = [];
}

export class UserAttribute {
    public id = '';
    public value = '';
    public attribute: Attribute;
    public attributeCode: string;
    public attributeGroupCode: string;
    public attributeGroup: AttributeGroup;
    public userAttributeList: UserAttributeList[] = []

    constructor(data: any = {}) {
        this.id = data.id;
        this.value = data.value ?? '';
        this.attribute = new Attribute(data.attribute);
        this.attributeGroup = new AttributeGroup(data.attributeGroup);
        this.attributeCode = data.attribute?.code || data.attributeCode;
        this.attributeGroupCode = data.attributeGroupCode;

        if (data.userAttributeList && Array.isArray(data.userAttributeList) && data.userAttributeList.length > 0) {
            for (const list of data.userAttributeList) {
                const userAttributeListItem = new UserAttributeList();
                userAttributeListItem.id = list.id;
                for (const itemData of list.items) {
                    userAttributeListItem.items.push(new UserAttribute(itemData));
                }
                this.userAttributeList.push(userAttributeListItem);
            }
        }
    }
}
