import { createState, useState } from '@hookstate/core';
import { Cart, CartItem } from '../models';

const cartState: any = createState(null);
const stateWrapper = (state: any) => ({
    get: (): Cart => state?.value,
    set: (cart: Cart) => state?.set((c: Cart) => c = cart),
    updateItem: (cartItem: CartItem) => {
        state?.set((c: Cart) => {
            var foundItem = c.items.find(i => i.id === cartItem.id);
            if (!cartItem.quantity)
                cartItem.quantity = 0;

            if (!foundItem || cartItem.quantity !== 0)
                c.updateProduct(cartItem);
            else
                c.removeProduct(cartItem.id);

            return c;
        });
    },
    updateBusinessInfo: (key: string, value: string) => {
        state?.set((c: Cart) => {
            if (key === "glCode")
                c.businessInfo.glCode = value;
            else if (key === "location")
                c.businessInfo.location = value;
            else if (key === "referenceNumber")
                c.businessInfo.referenceNumber = value;

            return c;
        });
    },
});

export const accessCart = () => stateWrapper(cartState);
export const useCart = () => stateWrapper(useState(cartState));