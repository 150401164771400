export class CartTotals {
    public tax = 0;
    public grand = 0;
    public subtotal = 0;
    public discount = 0;
    public shipping?: number = undefined;

    constructor(data: any = {}) {
        this.tax = data.tax || 0;
        this.grand = data.grand || 0;
        this.subtotal = data.subtotal || 0;
        this.discount = data.discount || 0;
        this.shipping = data.shippingGrand;
    }    
}