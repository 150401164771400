export class QueryPagination {
    public page = 1;
    public count = 0;
    public limit = 10;
    public serverPage = 1;
    public numberOfPages = 0;

    constructor (
        count = 0,
        numberOfPages = 0,
        page = 1,
        serverPage = 1,
    ) {
        this.page = page;
        this.count = count;
        this.serverPage = serverPage;
        this.numberOfPages = numberOfPages;
    }
}