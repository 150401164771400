export class OrderFraud {
    public id = 0;
    public caseId = '';
    public date = new Date();
    public action = '';

    constructor (data: any = {}) {
        this.id = data.id;
        this.caseId = data.caseId;
        this.date = new Date(data.date);
        this.action = data.action;
    }
}