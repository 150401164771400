import { Attribute } from "./Attribute";

export class AttributeGroup {
    public id = '';
    public name = '';
    public position = 0;
    public attributes: Attribute[] = [];

    constructor(data: any = {}) {
        if (!data || !data.id) 
            return;
        
        this.id = data.id;
        this.name = data.name;
        this.position = parseInt(data.position);

        if (data.attributes) {
            for (const attrData of data.attributes) {
                const attribute = new Attribute(attrData);
                this.attributes.push(attribute);
            }
        }
    }
}
