import { Address } from "../Address/Address";
import { CustomerCustomAttribute } from "./CustomerCustomAttribute";

export class Customer {
    public id = 0;
    public token = "";
    public email = "";
    public firstName = "";
    public lastName = "";
    public company = "";
    public phone = "";
    public password = "";
    public isSubscribed = false;

    public addresses: Address[] = [];

    public customAttributes: CustomerCustomAttribute[] = [];

    constructor(data: any = {}) {
        this.id = parseInt(data.id);
        this.token = data.token;
        this.email = data.email;
        this.lastName = data.lastName;
        this.password = data.password;
        this.phone = data.phone || data.telephone || '';
        this.firstName = data.firstName || data.fullName;
        this.company = data.company || data.organization || '';
        this.isSubscribed = data.isSubscribed || data.newsletter || false;

        if (data.addresses) {
            for (const address of data.addresses) {
                this.addresses.push(new Address(address));
            }
        }

        
        if (data.customAttributes) {
            for (const customAttribute of data.customAttributes) {
                this.customAttributes.push(new CustomerCustomAttribute(customAttribute));
            }
        }
    }

    public addAddress(address:any) {
        this.addresses.push(new Address(address));
    }

    public getShippingAddress():Address {
        for (const address of this.addresses) {
            if (address.defaultShipping === true) {
                return address;
            }
        }
        
        return this.addresses[0];
    }

    public getBillingAddress():Address {
        for (const address of this.addresses) {
            if (address.defaultBilling === true) {
                return address;
            }
        }
        return this.addresses[0];
    }

    public getAddressesExceptDefaults():Address[] {
        const addresses: Address[] = [];
        for (const address of this.addresses) {
            if (
                address.defaultShipping === true || 
                address.defaultBilling === true
            ) {
                continue;
            }
            addresses.push(address);
        }
        return addresses;
    }

    public getAllAddresses():Address[] {
        return this.addresses;
    }

    public getAddress(id:string):Address {
        const address = this.addresses.find(address => address.id === id);
        if (!address)
            return new Address();
            
        return address;
    }

    public getCustomAttribute(key: string) {
         const customAttribute = this.customAttributes.find((attr: CustomerCustomAttribute) =>  attr.key === key);
         if (customAttribute === undefined) {
             return null;
         }

         return customAttribute;
    }
}