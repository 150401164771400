import { Product } from "../Product/Product";

export class MyProductListItem {
    public id: string;
    public qty: number = 0;
    public product: Product;
    public productId: string;
    public createdAt: Date = new Date();
    public updatedAt: Date = new Date();    

    constructor(data: any = {}) {
        this.id = data.id;
        this.qty = data.qty;        
        this.productId = data.productId;
        this.product = data.product instanceof Product
            ? this.convertFromProxyToObject(data.product)
            : new Product(data.product);
    }

    convertFromProxyToObject(data: Product) {
        return new Product({
            ...data,
            gallery: data.gallery?.map(g => g),
            userTags: data.userTags?.map(u => u),
            imageRefs: data.imageRefs?.map(i => i),
            operationalInfo: {
                price: data.price,
                availability: data.availability
            }
        });
    }
}