export class Region {
    public id = '';
    public code = '';
    public name = '';

    constructor(data:any = {}) {
        // TODO: Remove this awful hack, the Gateway should return id as int
        // but is returning as string, so we need to parse it
        this.id = data.id || "";
        this.code = data.code || "";
        this.name = data.name || "";
    }
}