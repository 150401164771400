import { useEffect } from "react";

export default function ZohoSalesIQChat(widgetCode: String) {   
    var $zoho:any = (window as any).$zoho || {};
    (window as any).$zoho = $zoho;

    useEffect(() => {
        $zoho.salesiq = $zoho.salesiq || {widgetcode: widgetCode, values:{},ready:function(){}};

        var js = window.document.createElement("script"); 
        js.src = "https://salesiq.zoho.com/widget";
        js.type = "text/javascript"; 
        js.defer = true;
        js.id = 'zsiqscript';
        document.getElementsByTagName("head")[0].appendChild(js); 

        const chatDiv = document.createElement('div');
        chatDiv.id = 'zsiqwidget';
        document.body.append(chatDiv);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}
