import { Role, UserGroup, UserAttribute } from "../";
import { Address } from "../Address/Address";
import { UserSegment } from "./UserSegment";

export class User {
    public id = '';
    public slug = '';
    public email = '';
    public password = '';
    public role: Role = new Role();
    public groups: UserGroup[] = [];
    public parent: User | null = null;
    public sponsor: User | null = null;
    public segments: UserSegment[] = [];
    public userAttributes: UserAttribute[] = [];
    public data: any = {};

    public addresses: Address[] = [];

    constructor(data: any = {}) {
        this.slug = data.slug;
        this.email = data.email;
        this.parent = data.parent;
        this.sponsor = data.sponsor;
        this.password = data.password;
        this.id = data.id || data._id;
        this.role = new Role(data.role);

        this.groups = data.groups?.map((g: any) => new UserGroup(g)) || [];
        this.segments = data.segments?.map((s: any) => new UserSegment(s)) || [];
        this.userAttributes = data.userAttributes?.map((ua: any) => new UserAttribute(ua)) || [];

        this.data = data;
    }

    public getAttribute(code: string): UserAttribute | null {
        for (const userAttribute of this.userAttributes) {
            if (userAttribute.attribute.name === code) {
                return userAttribute;
            }
        }

        return null;
    }

    public getAttributeValue(code: string): string | null {
        if (code === 'email')
            return this.email;

        const userAttribute = this.getAttribute(code)
        if (userAttribute !== null) {
            return userAttribute.value
        }

        return null;
    }

    public getAllAddresses(company: User | null = null): Address[] {
        const addresses: Address[] = [];
        const addressBook = this.getAttribute('address');

        if (addressBook === null) return [];

        for (const addressList of addressBook.userAttributeList) {
            const address = new Address()
            address.fillFromUserAttributeList(addressList)

            if (!address.firstname) {
                address.firstname = this.getAttributeValue('firstname') || '';
            }
            if (!address.lastname) {
                address.lastname = this.getAttributeValue('lastname') || '';
            }
            addresses.push(address);
        }

        if (company && this.id !== company.id) {
            const companyAddresses = company.getAllAddresses()
            for (const companyAddress of companyAddresses) {
                companyAddress.alias += ' (Company)'
                addresses.push(companyAddress)
            }
        }

        return addresses;
    }

    public getAddress(id: string): Address {
        const addresses = this.getAllAddresses();
        for (const address of addresses) {
            if (address.id === id) {
                return address;
            }
        }

        return new Address();
    }

}
