export class OrderItem {
    public id = 0
    public sku = ''
    public name = ''
    public qtyOrdered = 0
    public price = 0
    public total = 0

    constructor (data: any = {}) {
      this.id = data.id
      this.sku = data.sku
      this.name = data.name
      this.qtyOrdered = data.qtyOrdered
      this.price = data.price
      this.total = data.total
    }
}
