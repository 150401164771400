import { Address } from "../Address/Address";
import { OrderPaymentInformation } from "./OrderPaymentInformation";

export class OrderPayment {
    public info: OrderPaymentInformation = new OrderPaymentInformation();
    public address: Address = new Address();

    constructor (data: any = {}) {
        if (data.paymentInformation) {
            this.info = new OrderPaymentInformation(data.paymentInformation);
        }
        
        if (data.address) {
            this.address = new Address(data.address);
        }
    }
}