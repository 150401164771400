export class CustomerCustomAttribute {
  public id = 0;
  public key = '';
  public value = '';

  constructor(data: any = {}) {
    this.id = parseInt(data.id);
    this.key = data.key;
    this.value = data.value;
  }
}