
import { Address } from "../Address/Address"

export class Npi {
  public id: string
  public npi: string
  public type: string
  public name: string
  public address: Address
  public normalizedAddress: string
  public normalizedAddressSimple: string

  constructor(data: any = {}) {
    this.id = data.id || data._id
    this.npi = data.npi
    this.type = data.type
    this.name = data.name
    this.address = new Address(data.address)
    this.normalizedAddress = data.normalizedAddress
    this.normalizedAddressSimple = data.normalizedAddressSimple
  }
}