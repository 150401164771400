export class OrderTotals {
    public subtotal = 0;
    public shipping = 0;
    public fee  = 0;
    public tax = 0;
    public discount = 0;
    public grand = 0;

    constructor (data: any = {}) {
        this.subtotal = data.subtotal;
        this.shipping = data.shipping;
        this.fee = data.fee;
        this.tax = data.tax;
        this.discount = data.discount;
        this.grand = data.grand;
    }

}