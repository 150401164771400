import { UserAttributeList } from "../";

export class Address {
    public id = "";
    public city = "";
    public codId = "";
    public email = "";
    public alias = "";
    public region = "";
    public street = "";
    public company = "";
    public regionId = '';
    public postcode = "";
    public lastname = "";
    public firstname = "";
    public telephone = "";
    public complement = "";
    public regionCode = "";
    public licenseNumber = "";
    public npi = "";
    public countryId = "US";
    public sameAsBilling = true;
    public defaultBilling = false;
    public defaultShipping = false;

    public proStatus = '';
    public proResults = '';
    public proNpiType = '';
    public proNpiNumber = '';
    public proEntityName = '';

    public attentionTo = '';
    public locationCountry = '';
    public locationEmail = '';
    public customerNumber = '';

    constructor (data: any = null) {
        if (data !== null)
            this.fill(data);
    }

    private fill (data: any) {
        this.id = data.id;
        this.city = data.city;
        this.email = data.email;
        this.region = data.region;
        this.street = data.street;
        this.complement = data.complement;
        this.alias = data.alias || "";
        this.regionId = data.regionId;
        this.postcode = data.postcode;
        this.lastname = data.lastname;
        this.countryId = data.countryId;
        this.firstname = data.firstname;
        this.telephone = data.telephone;
        this.company = data.company;
        this.regionCode = data.regionCode;
        this.complement = data.complement;
        this.licenseNumber = data.licenseNumber;
        this.npi = data.npi;
        this.sameAsBilling = data.sameAsBilling;
        this.defaultBilling = data.defaultBilling;
        this.defaultShipping = data.defaultShipping;

        this.proStatus = data.proStatus ?? ''
        this.proResults = data.proResults ?? ''
        this.proEntityName = data.proEntityName ?? ''
        this.proNpiNumber = data.proNpiNumber ?? ''
        this.proNpiType = data.proNpiType ?? ''

        this.attentionTo = data.attentionTo;
        this.locationCountry = data.locationCountry;
        this.locationEmail = data.locationEmail;
        this.customerNumber = data.customerNumber;
    }

    public fillFromUserAttributeList(addressList:UserAttributeList) {
        this.id = addressList.id;
        for (const addressData of addressList.items) {
            const attributeCode = addressData.attribute.code;
            if (attributeCode === 'address-alias') {
                this.alias = addressData.value;
            } else if (attributeCode === 'address-street') {
                this.street = addressData.value;
            } else if (attributeCode === 'address-complement') {
                this.complement = addressData.value;
            } else if (attributeCode === 'address-city') {
                this.city = addressData.value;
            } else if (attributeCode === 'address-state') {
                this.regionCode = addressData.value;
            } else if (attributeCode === 'address-country') {
                this.countryId = addressData.value;
            } else if (attributeCode === 'address-postcode') {
                this.postcode = addressData.value;
            } else if (attributeCode === 'address-sameAsShipping') {
                this.sameAsBilling = addressData.value === 'true';
            } else if (attributeCode === 'address-defaultShipping') {
                this.defaultShipping = addressData.value === 'true';
            } else if (attributeCode === 'address-defaultBilling') {
                this.defaultBilling = addressData.value === 'true';
            } else if (attributeCode === 'address-firstname') {
                this.firstname = addressData.value;
            } else if (attributeCode === 'address-lastname') {
                this.lastname = addressData.value;
            } else if (attributeCode === 'address-phone') {
                this.telephone = addressData.value;
            } else if (attributeCode === 'address-company') {
                this.company = addressData.value;
            } else if (attributeCode === 'address-pro-status') {
                this.proStatus = addressData.value
            } else if (attributeCode === 'address-pro-results') {
                this.proResults = addressData.value
            } else if (attributeCode === 'address-pro-entity-name') {
                this.proEntityName = addressData.value
            } else if (attributeCode === 'address-pro-npi-number') {
                this.proNpiNumber = addressData.value
            } else if (attributeCode === 'address-pro-npi-type') {
                this.proNpiType = addressData.value
            } else if (attributeCode === 'license-number') {
                this.licenseNumber = addressData.value
            } else if (attributeCode === 'npi') {
                this.npi = addressData.value
            } else if (attributeCode === 'attention-to') {
                this.attentionTo = addressData.value
            } else if (attributeCode === 'location-country') {
                this.locationCountry = addressData.value
            } else if (attributeCode === 'location-email') {
                this.locationEmail = addressData.value
            } else if (attributeCode === 'customer-number') {
                this.customerNumber = addressData.value
            }
        }
    }

    public static hasDefaultBillingAddress(addresses:Address[]):boolean {
        return addresses?.some(a => a.defaultBilling === true);
    }

    public static getBillingAddress(addresses:Address[]): Address {
        if (addresses.length === 0) 
            return new Address();

        for (const address of addresses) {
            if (address.defaultBilling === true)
                return address;
        }

        return addresses[0];
    }

    public static hasDefaultShippingAddress(addresses:Address[]):boolean {
        return addresses?.some(a => a.defaultShipping === true);
    }

    public static getShippingAddress(addresses:Address[]): Address {
        if (addresses.length === 0) 
            return new Address();
        
        for (const address of addresses) {
            if (address.defaultShipping === true)
                return address;
        }

        return addresses[0];
    }
}
