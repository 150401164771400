export class OrderShipmentItem {
  public itemId: number = 0;
  public sku: string = '';
  public shippedQty: number = 0;
  public carrierService: String = '';
  public trackingNumber: String = '';
  public status: string = ''
  public createdAt: Date = new Date();
  public updatedAt: Date = new Date();

  constructor (data: any = {}) {
    this.itemId = parseInt(data.itemId?.toString() || '0');
    this.sku = data.sku;
    this.shippedQty = parseInt(data.shippedQty?.toString() || '0');
    this.carrierService = data.carrierService;
    this.trackingNumber = data.trackingNumber;
    this.status = data.status;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
  }
}
