import React from 'react';

import loadingIcon from '../../../assets/images/loader-blue.gif';
import loadingIconInverted from '../../../assets/images/loader-white.gif';
import './styles.scss';

interface PropTypes {
    inverted?:boolean
}

const Loader = (props:PropTypes) => {
    return (
        <div className="loader">
            <img src={
                props.inverted ? loadingIconInverted : loadingIcon
            } alt="loader" />
        </div>
    );
};

export default Loader;