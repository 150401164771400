import { createState, useState } from "@hookstate/core";
import { User } from "../models";

const userState: any = createState(null)

const stateWrapper = (state: any) => ({
    get: (): User => state?.value,
    set: (user: User) => state?.set((u: User) => u = user),
});

export const accessUser = () => stateWrapper(userState);
export const useUser = () => stateWrapper(useState(userState));
