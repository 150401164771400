import { CartItem } from './CartItem';
import { CartTotals } from './CartTotals';
import { CartItemDiff } from './CartItemDiff';
import { CartCouponType } from './CartCouponType';
import { CartBusinessInfo } from './CartBusinessInfo';
import { currencyFormat } from '../../utils/currencyFormatter';

export class Cart {
    public id = "";
    public type = "";
    public token = "";
    public email = "";
    public userId = "";
    public isActive = false;
    public hasShippingInfo = false;
    public totals = new CartTotals();
    public items = new Array<CartItem>();
    public businessInfo = new CartBusinessInfo();
    public itemDiffs = new Array<CartItemDiff>();
    public coupons = new Array<CartCouponType>();

    constructor(data: any = {}) {
        this.id = data.id;
        this.type = data.type;
        this.email = data.email;
        this.userId = data.userId;
        this.isActive = data.isActive;
        this.coupons = data.couponCode;
        this.hasShippingInfo = !!data.shipping?.shippingInformation?.carrierCode;

        this.businessInfo = new CartBusinessInfo({
            glCode: data.glCode,
            location: data.location,
            referenceNumber: data.referenceNumber,
        });
        
        if (data.token)
            this.token = data.token;

        if (data.totals)
            this.totals = new CartTotals(data.totals);

        if (data.coupons) {
            this.coupons = data.coupons
                .map((c: any) => new CartCouponType(c));
        }

        if (data.items)
            this.setItems(data.items);

        if (data.itemDiffs?.length > 0)
            this.setItemsDiffs(data.itemDiffs);
    }

    public setItems(cartItems: any) {
        cartItems.forEach((ci: any) => {
            this.items.push(new CartItem({
                id: ci.id,
                name: ci.name,
                price: ci.price,
                total: ci.total,
                image: ci.image,
                quantity: ci.qty,
                grokitId: ci.sku,
            }));
        });
    }

    public setItemsDiffs(items: any) {
        items.forEach((cid: any) => {
            this.itemDiffs.push(new CartItemDiff({
                item: cid.item,
                action: cid.action,
                oldPrice: cid.oldPrice,
                newPrice: cid.newPrice,
            }));
        });
    }

    public setToken(token: string): void {
        this.token = token;
    }

    public getToken(): string {
        return this.token;
    }

    public updateProduct(item: CartItem) {
        var foundItem = this.items.find((cp) => cp.id === item.id);
        if (foundItem) {
            if (item.quantity === 0) {
                var foundItemIndex = this.items.findIndex((cp) => cp.id === item.id);
                this.items.splice(foundItemIndex, 1);
            } else {
                foundItem.quantity = item.quantity;
            }
        } else {
            this.items.push(item);
        }
    }

    public removeProduct(id: string) {
        var foundItem = this.items.findIndex((cp) => cp.id === id);
        this.items.splice(foundItem, 1);
    }

    public clearCart() {
        this.items = new Array<CartItem>();
        this.totals = new CartTotals({});
    }

    public getTotalPrice(): string {
        return currencyFormat(this.totals.grand);
    }

    public getSubtotalPrice(): string {
        return currencyFormat(this.totals.subtotal);
    }

    public getItemsQuantity(): number {
        return this.items.reduce((acc, curr) => acc += curr.quantity, 0);
    }
}