import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

interface PropTypes {
    slug: string;
    title: string;
    setMobileMenuOpen(state: boolean): any;
};

export default function ManageCustomersMenuItem(props: PropTypes) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get("userId");

    const isActive = props.slug === "customers" && (
        location.pathname.includes("/my-account/manage-customers/customers") ||
        (location.pathname.includes("/my-account/addresses") && userId)
    );

    return (
        <NavLink
            className="link"
            activeClassName="active"
            onClick={() => props.setMobileMenuOpen(false)}
            to={"/my-account/manage-customers/" + props.slug}
            isActive={(match, location) => !!isActive}
        >
            {props.title}
        </NavLink>
    );
}