import settings from '../settings';
import { Cart, Product } from '../models';
import ProductService from './ProductService';
import UserStorage from '../utils/userStorage';
import CartStorage from '../utils/cartStorage';
import { accessCart } from '../stores/cartStore';
import TagManagerService from './TagManagerService';
import ErrorHandlingService from './ErrorHandlingService';
import CartRepository from '../repositories/CartRepository';

export default class CartService {
    static async create(): Promise<void> {
        CartStorage.destroy();
        var userToken = UserStorage.getToken();
        const cartToken = await CartRepository.create(userToken)
            .catch(e => {
                var errors = ErrorHandlingService.getMessages(e);

                if (errors.some(e =>
                    e.code.indexOf("401") >= 0 ||
                    e.message.indexOf("401") >= 0
                )) {
                    UserStorage.logout();
                }

                throw errors;
            });
        CartStorage.setToken(cartToken);
        accessCart().set(new Cart({ token: cartToken }));
    }

    static async get(forceUpdate = false, retry = true): Promise<Cart> {
        var userToken = UserStorage.getToken();
        var cartToken = CartStorage.getToken() || "";
        try {
            const cart = await CartRepository.get(cartToken, forceUpdate, userToken);
            if (UserStorage.get() && cart.id !== CartStorage.getToken())
                CartStorage.setToken(cart.id);

            accessCart().set(cart);
            return cart;
        } catch (e) {
            if (retry) {
                await this.create();
                return await this.get(forceUpdate, false);
            }

            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async clearCart(): Promise<Cart> {
        const userToken = UserStorage.getToken();
        const cartToken = CartStorage.getToken();
        const updatedCart = await CartRepository.clearCart(
            cartToken || "",
            userToken
        ).catch(e => { throw ErrorHandlingService.getMessages(e); });
        accessCart().set(updatedCart);

        return updatedCart;
    }

    static async getQuote(cartToken: string): Promise<Cart> {
        var userToken = UserStorage.getToken();
        try {
            const cart = await CartRepository.get(cartToken, false, userToken);
            if (UserStorage.get() && cart.id !== CartStorage.getToken())
                CartStorage.setToken(cart.id);

            accessCart().set(cart);
            return cart;
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async addItem(product: Product, quantity?: number): Promise<Cart> {
        if (product.availabilityId === "unavailable") {
            throw ErrorHandlingService.createError(
                "UNAVAILABLE_PRODUCT",
                "Product unavailable"
            );
        }

        var userToken = UserStorage.getToken();
        var cartToken = CartStorage.getToken();
        const updatedCart = await CartRepository.addUpdateItem(
            product.id,
            quantity,
            true,
            cartToken || "",
            userToken
        ).catch(e => { throw ErrorHandlingService.getMessages(e); });
        accessCart().set(updatedCart);

        if (settings.env.REACT_APP_ENVIRONMENT === 'production') {
            ProductService.productsAddedToCart(product.id);
        }

        TagManagerService.registerAddedToCart(product, quantity || 1);

        return updatedCart;
    }

    static async updateItem(id: string, quantity: number): Promise<Cart> {
        var userToken = UserStorage.getToken();
        var cartToken = CartStorage.getToken();
        const updatedCart = await CartRepository.addUpdateItem(
            id,
            quantity,
            false,
            cartToken || "",
            userToken
        ).catch(e => { throw ErrorHandlingService.getMessages(e); });
        accessCart().set(updatedCart);

        return updatedCart;
    };

    static removeItem(id: string): Promise<Cart> {
        return CartService.updateItem(id, 0);
    };

    static async transferCartFromGuestToCustomer(externalCartId?: string): Promise<string> {
        var userToken = UserStorage.getToken();
        var cartToken = externalCartId || CartStorage.getToken();
        const newCartToken = await CartRepository.transferCartFromGuestToCustomer(
            cartToken || "", userToken
        ).catch(e => { throw ErrorHandlingService.getMessages(e); });

        CartStorage.setToken(newCartToken);
        return newCartToken;
    }

    static async setBusinessInfo(
        key: string,
        value: string,
    ): Promise<boolean> {
        const userToken = UserStorage.getToken();
        const cartToken = CartStorage.getToken();
        const result = await CartRepository.setBusinessInfo(
            key,
            value,
            cartToken || "",
            userToken
        ).catch(e => { throw ErrorHandlingService.getMessages(e); });
        
        if (result)
            accessCart().updateBusinessInfo(key, value);

        return result;
    }

    static async setProductListId(productListId: string): Promise<boolean> {
        const userToken = UserStorage.getToken();
        const cartToken = CartStorage.getToken();
        const result = await CartRepository.setProductListId(
            productListId,
            cartToken || "",
            userToken
        ).catch(e => { throw ErrorHandlingService.getMessages(e); });

        return result;
    }

    static async addCoupon(code: string): Promise<Cart> {
        const userToken = UserStorage.getToken();
        const cartToken = CartStorage.getToken();
        const updatedCart = await CartRepository.addCoupon(
            code,
            cartToken || "",
            userToken
        ).catch(e => { throw ErrorHandlingService.getMessages(e); });
        accessCart().set(updatedCart);

        return updatedCart;
    }

    static async deleteCoupons(couponCodes: string[]): Promise<Cart> {
        const userToken = UserStorage.getToken();
        const cartToken = CartStorage.getToken();
        const updatedCart = await CartRepository.deleteCoupons(
            cartToken || "",
            couponCodes,
            userToken
        ).catch(e => { throw ErrorHandlingService.getMessages(e); });
        accessCart().set(updatedCart);

        return updatedCart;
    }
}