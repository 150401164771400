import { ProductHistory } from './ProductHistory';
import { ProductAttribute, ProductPrice } from '..';
import { ReactiveProduct } from './ReactiveProduct';
import { ProductUpdateKey } from './ProductUpdateKey';
import { currencyFormat } from '../../utils/currencyFormatter';

export class Product {
    public id = "";
    public sku = "";
    public title = "";
    public source = "";
    public sourceUrl = "";
    public packaging = "";
    public description = "";
    public isActive = false;
    public availability = "";
    public thumbnailUrl = "";
    public availabilityId = "";
    public descriptionRichText = "";
    public isUpdateInProgress = false;
    public price = new ProductPrice();
    public gallery = new Array<string>();
    public userTags = new Array<string>();
    public features = new Array<string>();
    public imageRefs = new Array<string>();
    public variants = new Array<Product>();
    public variantIds = new Array<string>();
    public categories = new Array<string>();
    public attachments = new Array<string>();
    public updateKey = new ProductUpdateKey();
    public history = new Array<ProductHistory>();
    public equivalentProductIds = new Array<string>();
    public attributes = new Array<ProductAttribute>();
    public labeledComponents = new Array<ProductAttribute>();

    constructor(data: any = null, variants?: any[]) {
        if (!data)
            return;

        this.sku = data.sku;
        this.source = data.source;
        this.id = data.id || data.sku;
        this.isActive = data.isActive;
        this.features = data.features;
        this.packaging = data.packaging;
        this.sourceUrl = data.sourceUrl;
        this.updateKey = data.updateKey;
        this.variantIds = data.variantIds;
        this.categories = data.categories;
        this.userTags = data.userTags ?? [];
        this.title = data.title || data.name;
        this.imageRefs = data.imageRefs || [];
        this.gallery = data.images?.gallery || [];
        this.descriptionRichText = data.description;
        this.equivalentProductIds = data.equivProductIds;
        this.isUpdateInProgress = data.isUpdateInProgress;
        this.description = data.description?.replace( /(<([^>]+)>)/ig, '');
        this.thumbnailUrl =
            (this.gallery && this.gallery[0]) ||
            (data.imageRefs && data.imageRefs[0]) || "";
        this.availability = data.operationalInfo?.availability || "Unavailable";
        this.availabilityId = this.availability.replace(" ", "-").toLowerCase();

        if (data.operationalInfo?.price)
            this.price = new ProductPrice(data.operationalInfo.price);

        data.operationalHistory && data.operationalHistory.forEach((info: any) => {
            const entry = new ProductHistory(info);

            if (this.history.length > 0) {
                const lastAddedEntry = this.history[this.history.length - 1]
                if (
                    entry.price.price === lastAddedEntry.price.price &&
                    entry.price.specialPrice === lastAddedEntry.price.specialPrice
                ) {
                    return;
                }
            }
            this.history.push(entry);
        });

        data.attributes && data.attributes.forEach((attribute: any) => {
            if (!attribute.value)
                return;

            if (attribute.value.__typename === "Assets") {
                this.attachments.push(...attribute.value.assets);
            } else {
                const productAttribute = new ProductAttribute(attribute);
                this.attributes.push(productAttribute);
            }
        });

        data.labeledComponents && data.labeledComponents.forEach((attribute: any) => {
            if (!attribute.value)
                return;

            const productAttribute = new ProductAttribute(attribute);
            this.labeledComponents.push(productAttribute);
        });

        if (variants)
            this.variants = variants.map(v => new Product(v));
    }

    public hasSpecialPrice(): boolean {
        if (this.price === null || this.price === undefined)
            return false;

        return !!this.price.specialPrice &&
            this.price.specialPrice !== this.price.price;
    }

    public getPrice(quantity = 1): string {
        if (this.price === null || this.price === undefined)
            return '-';

        if (this.price.price)
            return currencyFormat(this.price.price * quantity);

        return this.price.specialPrice ?
            currencyFormat(this.price.specialPrice * quantity) : '-';
    }

    public getSpecialPrice(quantity = 1): string | null {
        if (this.price === null || this.price === undefined)
            return null;

        return this.price.specialPrice ?
            currencyFormat(this.price.specialPrice * quantity) : '-';
    }

    public toReactive(): ReactiveProduct {
        return new ReactiveProduct({
            id: this.id,
            price: this.price.price,
            updateKey: this.updateKey,
            availability: this.availability,
            specialPrice: this.price.specialPrice,
        });
    }
}