export class PurchaseOrderItem {
    public orderId = 0;
    public sourceId = '';
    public sourceSku = '';
    public qtyOrdered = 0;
    public qtyBackordered = 0;
    public qtyShipped = 0;
    public cost = 0;
    public total = 0;

    constructor (data: any = {}) {
      this.orderId =  data.orderId || 0;
      this.sourceId = data.sourceId;
      this.sourceSku = data.sourceSku;
      this.qtyOrdered = parseInt(data.qtyOrdered);
      this.qtyBackordered = data.qtyBackordered;
      this.qtyShipped = data.qtyShipped;
      
      this.cost = 0;
      if (this.qtyOrdered > 0) {
        this.cost = parseFloat(data.total) / this.qtyOrdered;
      }
      
      this.total = parseFloat(data.total);
    }
}
