import { createState, useState } from '@hookstate/core';
import { ReactiveProduct } from '../models/Product/ReactiveProduct';

const listState:any = createState([]);
const stateWrapper = (state:any) => ({
    get: (id:string):ReactiveProduct => 
        state?.value?.find((r:ReactiveProduct) => r.id === id),
    isUpdateInProgressFor: (productsIds:string[]):boolean => {
        var isUpdating = state?.value?.some((r:ReactiveProduct) => 
            productsIds.includes(r.id) && !r.updated
        );
        
        return isUpdating;
    },
    add: (newProduct:ReactiveProduct) => { 
        state?.set((rx:ReactiveProduct[]) => {
            var foundItem = rx.find(i => i.id === newProduct.id);
            if (foundItem) {
                foundItem.price = newProduct.price;
                foundItem.availability = newProduct.availability;
            } else {
                rx.push(newProduct);
            }                

            return rx;
        });
    },
    remove: (productToRemove:ReactiveProduct) => { 
        state?.set((rx:ReactiveProduct[]) => {
            var foundItem = rx.find(i => i.id === productToRemove.id);
            if (!foundItem)
                return rx;
                
            var itemIndex = rx.findIndex((rp) => rp.id === foundItem!.id);
            rx.splice(itemIndex, 1);

            return rx;
        });
    },
    update: (updatedItem:ReactiveProduct) => { 
        state?.set((r:ReactiveProduct[]) => {
            var foundItem = r.find(i => i.id === updatedItem.id);
            if (foundItem) {
                foundItem.updated = true;
                foundItem.price = updatedItem.price;
                foundItem.specialPrice = updatedItem.specialPrice;
                foundItem.availability = updatedItem.availability;
            }

            return r;
        });
    },
});

export const accessReactiveProducts = () => stateWrapper(listState);
export const useReactiveProducts = () => stateWrapper(useState(listState));