import { Address } from "../Address/Address";

export class CreditCard {
    public cvv?: number;
    public bin?: string;
    public type?: string;
    public alias?: string;
    public last4?: string;
    public number: string;
    public company?: boolean;
    public holderName?: string;
    public expirationYear: number;
    public expirationMonth: number;

    public billingAddress: Address;

    constructor (data: any) {
        this.cvv = data.cvv;
        this.bin = data.bin;
        this.type = data.type;
        this.alias = data.alias;
        this.last4 = data.last4;
        this.company = data.company;
        this.holderName = data.holderName || '';
        this.expirationYear = data.expirationYear;
        this.expirationMonth = data.expirationMonth;
        this.number = data.number || data.maskedNumber;
        this.billingAddress = new Address(data.billingAddress)
    }

    public getMaskedNumber(): string {
        if (this.last4) {
            return '********-' + this.last4;
        } else if (this.number) {
            return this.number;
        }
        return '*******';
    }
}