import { InvoiceItem } from "./InvoiceItem";

export class Invoice {
    public id = 0;
    public state = '';
    public version = '';
    public container = '';
    public createdAt = new Date();
    public invoicedAt = new Date();
    public number = '';
    public source = '';
    public poInstanceId = 0;
    public invStatus = '';
    public subtotal = 0;
    public shippingTotal = 0;
    public taxAmount = 0;
    public feeTotal = 0;
    public invoiceTotal = 0;

    public items: InvoiceItem[] = [];

    constructor(data: any = {}) {
        this.id = parseInt(data.id || 0);
        this.state = data.state;
        this.version = data.version;
        this.container = data.container;
        this.createdAt = new Date(data.createdAt);
        this.invoicedAt = new Date(data.invoicedAt);
        this.number = data.number;
        this.source = data.source;
        this.poInstanceId = data.poInstanceId;
        this.invStatus = data.invStatus;
        this.subtotal = data.subtotal;
        this.shippingTotal = data.shippingTotal;
        this.taxAmount = data.taxAmount;
        this.feeTotal = data.feeTotal;
        this.invoiceTotal = data.invoiceTotal;

        if (data.items) {
            for (const item of data.items) {
                this.items.push(new InvoiceItem(item));
            }
        }
    }
}
