export class OrderInfoOption {
    public isEnabled = true;
    public default = '';
    public required = false;

    constructor(data: any = {}) {
        this.isEnabled = data.isEnabled === true;
        this.default = data.default ?? '';
        this.required = data.required === true;
    }
}