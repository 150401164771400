export class OrderHoldItem {
    public orderedId = '';
    public quantity = 0;
    public name = '';

    constructor (data: any = {}) {
      this.orderedId = data.orderedId;
      this.quantity = data.quantity;
      this.name = data.name;
    }
}
