import { ProductPrice } from "./ProductPrice";

export class ProductHistory {
    public createdTime = new Date();
    public price = new ProductPrice();

    constructor(data: any = {}) {
        this.price = new ProductPrice(data.price);
        this.createdTime = new Date(data.createdTime);
    }

    public getPrice(): number {
        if (this.price === null || this.price === undefined)
            return 0;

        return this.price.specialPrice || this.price.price;
    }
}