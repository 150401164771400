import {
    QueryPagination as Pagination,
    ParameterGroup,
    ParameterCategory,
    SearchParameter,
    Product
} from '../models';
import { gql } from '@apollo/client';
import { ProductTag } from '../models/Product/ProductTag';
import uhmpcClient from './graphql-clients/UhmpcApiClient';
import { ParameterSource } from '../models/ParameterSource';
import ErrorHandlingService from '../services/ErrorHandlingService';
import { ReactiveProduct } from '../models/Product/ReactiveProduct';
import { ProductUpdateKey } from '../models/Product/ProductUpdateKey';
import { accessReactiveProducts } from '../stores/reactiveProductStore';
import { ProductSearchResult } from '../models/Product/ProductSearchResult';

export default class ProductRepository {
    static async getSampleProducts(token: string): Promise<Product[]> {
        const query = gql`
            query {
                products: productSample {
                    id
                    sku
                    title
                    source
                    userTags
                    imageRefs
                    updateKey
                    variantIds
                    description
                    isUpdateInProgress
                    operationalInfo {
                        availability
                        price {
                            price
                            specialPrice
                        }
                    }
                }
            }
        `;

        const context: any = {};
        if (token)
            context.headers = { "Authorization": token };

        const products = await uhmpcClient
            .query({ query, context })
            .then((result: any) => {
                if (result?.data?.products) {
                    return result.data.products;
                } else {
                    throw ErrorHandlingService.createError(
                        "CouldNotGetProducts",
                        "Could not get sample products"
                    );
                }
            });

        return products.map((p: any) => new Product(p));
    }

    static async getSampleByIds(ids: string[]): Promise<Product[]> {
        const query = gql`
            query products($ids: [String!]!) {
                products: productsById(ids: $ids) {
                    id
                    sku
                    title
                    availability
                    description
                    imageRefs
                    variantIds
                    price {
                        price
                        specialPrice
                    }
                }                
            }
        `;

        const variables = { ids };
        const data = await uhmpcClient
            .query({
                query,
                variables
            })
            .then((result: any) => {
                if (result?.data?.products) {
                    return result.data.products;
                } else {
                    throw ErrorHandlingService.createError(
                        "ProductNotFound",
                        "Product not found"
                    );
                }
            });

        return data.map((p: any) => new Product(p))
    }

    static async typeahead(
        params: SearchParameter, 
        token: string
    ): Promise<Product[]> {
        const variables: any = {
            categories: [],
            filterParams: [],
            phrase: params.phrase,
        };

        const sources = params.sourceFilterParameters?.length
            ? params.sourceFilterParameters
            : (params.source ? [params.source] : undefined);
        if (sources)
            variables.sources = sources;
        if (params.catceptId)
            variables.catceptId = params.catceptId;
        if (params.availabilities && params.availabilities.length > 0)
            variables.availabilities = params.availabilities;

        const query = gql`
            query search(
                $page: Int = 1,
                $limit: Int = 5,
                $sources: [String!],
                $catceptId: String,
                $phrase: String!,
                $availabilities: [String!],
                $filterParams: [InputSearchParameter!]
            ) {
                search: searchProducts(
                    page: $page, 
                    limit: $limit, 
                    phrase: $phrase, 
                    sources: $sources,
                    catceptId: $catceptId,
                    filterParams: $filterParams,
                    availabilities: $availabilities
                ) {
                    resultCount
                    hits {
                        id
                        sku
                        title
                        userTags
                        imageRefs
                        updateKey
                        variantIds
                        description
                        isUpdateInProgress
                        operationalInfo {
                            availability
                            price {
                                price
                                specialPrice
                            }
                        }
                    }                    
                }                
            }
        `;

        const context: any = {};
        if (token)
            context.headers = { "Authorization": token };

        const products = await uhmpcClient
            .query({
                query,
                context,
                variables,
            })
            .then((result: any) => {
                if (result?.data?.search) {
                    return result.data.search.hits || [];
                } else {
                    throw ErrorHandlingService.createError(
                        "CouldNotGetProducts",
                        "Could not get typeahead products"
                    );
                }
            });

        return products.map((p: any) => new Product(p));
    }

    static async search(params: SearchParameter, token: string): Promise<ProductSearchResult> {
        const offset = params.offset || 1;
        const filterParams = params.groupFilterParameters
            ? params.groupFilterParameters
            : [];
        const categories = params.categoryFilterParameters
            ? params.categoryFilterParameters
            : [];
        const sources = params.sourceFilterParameters?.length
            ? params.sourceFilterParameters
            : (params.source ? [params.source] : undefined);

        const variables: any = {
            categories,
            page: offset,
            filterParams,
            tags: params.tags,
            manufacturers: params.manufacturers,
            phrase: params.phrase,            
            limit: params.resultsPerPage,
        };

        if (sources)
            variables.sources = sources;

        if (params.availabilities && params.availabilities.length > 0)
            variables.availabilities = params.availabilities;

        if (!params.phrase || params.phrase.trim() === "")
            variables.phrase = null;
        
        if (params.catceptId)
            variables.catceptId = params.catceptId;

        const query = gql`
            query search(
                $phrase: String, 
                $page: Int = 1, 
                $limit: Int = 10, 
                $filterParams: [InputSearchParameter!],
                $categories: [String!],
                $sources: [String!],
                $availabilities: [String!],
                $manufacturers: [String!],
                $tags: [String!],
                $catceptId: String
            ) {
                search: searchProducts(
                    page: $page, 
                    phrase: $phrase, 
                    limit: $limit, 
                    filterParams: $filterParams,
                    categories: $categories,
                    sources: $sources,
                    manufacturers: $manufacturers,
                    availabilities: $availabilities,
                    tags: $tags,
                    catceptId: $catceptId
                ) {
                    resultCount
                    categories {
                        value
                        count
                    }
                    sources {
                        value
                        count
                    }
                    manufacturers {
                        value
                        count
                    }
                    parameterGroups {
                        name
                        totalCount
                        parameters {
                            value
                            count
                        }
                    }
                    hits {
                        id
                        sku
                        title
                        source
                        userTags
                        imageRefs
                        updateKey
                        categories
                        variantIds
                        isUpdateInProgress
                        operationalInfo {
                            availability
                            price {
                                price
                                specialPrice
                            }
                        }
                    }
                }                
            }
        `;

        const context: any = {};
        if (token)
            context.headers = { "Authorization": token };

        const searchResult = await uhmpcClient
            .query({
                query,
                context,
                variables,
            })
            .then((result: any) => {
                if (result?.data?.search) {
                    var data = result.data.search;
                    var products = data.hits || [];

                    const pageSize = params.resultsPerPage;
                    var numberOfPages = Math.ceil(data.resultCount / pageSize);
                    var response = {
                        products: products.map((p: any) => new Product(p)),
                        pagination: new Pagination(
                            data.resultCount,
                            numberOfPages,
                            Math.floor(offset / pageSize),
                            offset,
                        ),
                        parameterSources:
                            ParameterSource.createArray(data.sources),
                        parameterManufacturers:
                            ParameterSource.createArray(data.manufacturers),
                        parameterGroups:
                            ParameterGroup.createArray(data.parameterGroups),
                        parameterCategories:
                            ParameterCategory.createArray(data.categories)
                    };

                    return response;
                } else {
                    throw ErrorHandlingService.createError(
                        "CouldNotGetProducts",
                        "Could not get typeahead products"
                    );
                }
            });

        return searchResult;
    }

    static async getProductsById(
        ids: string[], 
        token: string
    ): Promise<Product[]> {
        const query = gql`
            query productsById($ids: [String!]!) {
                products: productsById(ids: $ids) {
                    id
                    sku
                    title
                    source
                    isActive
                    userTags
                    updateKey
                    isUpdateInProgress
                    operationalInfo {
                        availability
                        price {
                            price
                            specialPrice
                        }
                    }
                    images {
                        gallery
                    }
                }                
            }
        `;

        const variables = { ids };

        const context: any = {};
        if (token)
            context.headers = { "Authorization": token };

        const data = await uhmpcClient
            .query({
                query,
                context,
                variables,
                fetchPolicy: "network-only"
            })
            .then((result: any) => {
                if (result?.data?.products) {
                    return result.data.products;
                } else {
                    throw ErrorHandlingService.createError(
                        "ProductsNotFound",
                        "Products not found"
                    );
                }
            });

        return data.map((p: any) => new Product(p));
    }

    static async getProductById(
        id: string,
        token: string
    ): Promise<Product> {
        const query = gql`
            query {
                products: productDetails(id: "${id}") {
                    product {
                        id
                        sku
                        source
                        isActive
                        sourceUrl
                        title
                        userTags
                        features
                        equivProductIds
                        updateKey
                        isUpdateInProgress
                        packaging
                        variantIds
                        description
                        operationalInfo {
                            availability
                            price {
                                price
                                specialPrice
                            }
                        }
                        operationalHistory {
                            price {
                                price
                                specialPrice
                            }
                            availability
                            createdTime
                        }
                        imageRefs
                        images {
                            gallery
                        }
                        categories
                        attributes {
                            name
                            value {
                                ... on Assets {
                                    assets
                                }
                                ... on StringValue {
                                    string
                                }
                                ... on Html {
                                    html
                                }
                                ... on ListValue {
                                    list
                                }
                                ... on Table {
                                    header
                                    rows
                                }
                            }      
                        }
                        labeledComponents {
                            name
                            value {
                                ...on StringValue {
                                    string
                                }
                            }
                        }
                    }
                    allVariants {
                        id
                        title
                        isActive
                        packaging
                        description
                        operationalInfo {
                            availability
                            price {
                                price
                                specialPrice
                            }
                        }
                        labeledComponents {
                            name
                            value {
                                ...on StringValue {
                                    string
                                }
                            }
                        }
                        attributes {
                            name
                            value {
                                ... on Assets {
                                    assets
                                }
                                ... on StringValue {
                                    string
                                }
                                ... on Html {
                                    html
                                }
                                ... on ListValue {
                                    list
                                }
                                ... on Table {
                                    header
                                    rows
                                }
                            }      
                        }
                    }
                }                
            }
        `;

        const context: any = {};
        if (token)
            context.headers = { "Authorization": token };

        const data = await uhmpcClient
            .query({
                query,
                context,
            })
            .then((result: any) => {
                if (result?.data?.products) {
                    return result.data.products;
                } else {
                    throw ErrorHandlingService.createError(
                        "ProductNotFound",
                        "Product not found"
                    );
                }
            });

        return new Product(data.product, data.allVariants);
    }

    static async getTags(token: string): Promise<ProductTag[]> {
        const query = gql`
            query {
                tags: listAllTags {
                    tag
                    count
                }                
            }
        `;

        const context: any = {};
        if (token)
            context.headers = { "Authorization": token };

        const tags = await uhmpcClient
            .query({
                query,
                context
            })
            .then((result: any) => {
                if (result?.data?.tags) {
                    const tags: ProductTag[] = [];
                    for (const tag of result.data.tags)
                        tags.push(new ProductTag(tag));

                    return tags;
                } else {
                    throw ErrorHandlingService.createError(
                        "ProductNotFound",
                        "Product not found"
                    );
                }
            });

        return tags;
    }

    static async productsAddedToCart(id: string, token: string): Promise<boolean> {
        const mutation = gql`
            mutation productsAddedToCart($ids:[String!]!) {
                productsAddedToCart (ids: $ids)
            }
        `;

        const variables = {
            ids: [id]
        };

        const context: any = {};
        if (token)
            context.headers = { "Authorization": token };

        return await uhmpcClient
            .mutate({
                context,
                mutation,
                variables,
            })
            .then((result: any) => {
                if (result?.data?.productsAddedToCart) {
                    return result.data.productsAddedToCart === true;
                } else {
                    throw ErrorHandlingService.createError(
                        'COULD_NOT_REPORT_ADD_PRODUCT',
                        `Could report add [${id}] to cart`
                    );
                }
            });
    }

    static subscribeTo(updateKey: ProductUpdateKey): void {
        const rxProducts = accessReactiveProducts();
        const hasSubscription = rxProducts.get(updateKey.id);
        if (hasSubscription)
            return;

        const query = gql`
            subscription availability($inputs: [UpdateKey!]!) {
                availabilityUpdateStream(inputs:$inputs) {
                    inputIndex
                    product {
                        id
                        operationalInfo {
                            availability
                            price {
                                price
                                specialPrice
                            }
                        }
                    }
                }
            }
        `;

        const variables = { inputs: [updateKey] };

        uhmpcClient.subscribe({
            query, variables
        }).subscribe({
            next(response) {
                const product = response.data.availabilityUpdateStream.product;
                if (product.id !== updateKey.id)
                    return;

                const rxProduct = new ReactiveProduct({
                    updateKey,
                    id: product.id,
                    price: product.operationalInfo?.price?.price,
                    availability: product.operationalInfo?.availability,
                    specialPrice: product.operationalInfo?.price?.specialPrice,
                });
                rxProducts.update(rxProduct);
            }
        });
    }
}