import { Parameter } from "./Parameter";

export class ParameterSource {
    public static createArray(data: any): Parameter[] {
        let sources = new Array<Parameter>();
        data.forEach((parameter: any) =>
            sources.push(new Parameter(parameter.value, parameter.count))
        );

        return sources;
    }
}