export class ReactiveProduct {
    public id = "";
    public price = 0;
    public updated = false;
    public specialPrice = 0;
    public availability = "";
    public updateKey:any = {};

    constructor(data:any = {}) {
        this.id = data.id;
        this.price = data.price;
        this.updated = data.updated;
        this.updateKey = data.updateKey;
        this.specialPrice = data.specialPrice;
        this.availability = data.availability;
    }
}