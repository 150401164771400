import React from "react";
import { Link } from "react-router-dom";

import './styles.scss';

export default function Breadcrumbs(props:any) {
    return (
        <div className={
            "breadcrumb" + 
            (window.location.pathname.indexOf("my-account") >= 0 ? " logged" : "")
        }>
            {props.paths && props.paths.map((p:any, i:number) => 
                <Link to={p.path} key={i}>
                    {p.name}
                </Link>
            )}
        </div>
    );
}