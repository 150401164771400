export const formatDate = (date: Date | string) => {
  const dateStr = date instanceof Date ? date.toISOString() : date;
  const dateFormatted = dateStr.endsWith('Z') ? dateStr : dateStr + 'Z';

  return new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
  }).format(new Date(dateFormatted));
};
