export class ProductUpdateKey {
    public id = "";
    public url = "";
    public sortKey = "";

    constructor(data:any = {}) {
        this.id = data.id;
        this.url = data.url;
        this.sortKey = data.sortKey;
    }
}