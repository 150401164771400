export class UserSegment {
    public id = '';
    public name = '';
    public code = '';

    constructor(data: any = {}) {
        this.id = data.id;
        this.name = data.name;
        this.code = data.code;
    }
}
