import React, { FC, ReactNode } from 'react';

import './styles.scss';
import { formatDate } from '../../../utils/dateFormatter';

interface PropTypes {
    title?: string | React.ReactNode;
    additionalHeaderInfo?: ReactNode;
    requestBy?: string;
    requestAt?: Date;
};

const InfoCard: FC<PropTypes> = (props) => {
    return (
        <div className="info-card">
            {(props.title || props.additionalHeaderInfo) && 
                <div className="title">
                    <h2>{props.title}</h2>
                    {props.additionalHeaderInfo}
                </div>
            }
            <div className="content">
                {props.children}
            </div>
            {(props.requestBy || props.requestAt) && (
                <div className="footer">
                    {props.requestBy && (
                        <div className="footer-item">
                            <span className='footer-title'>Request By:</span> {props.requestBy}
                        </div>
                    )}
                    {props.requestAt && (
                        <div className="footer-item">
                            <span className='footer-title'>Request Date:</span> {props.requestAt}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default InfoCard;
