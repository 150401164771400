export class FilterParameter {
    public key = '';
    public value = '';

    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }
}

export class SearchParameter {
    public offset = 1;
    public phrase = "";
    public source = "";
    public catceptId = "";
    public resultsPerPage = 10;
    public tags = new Array<string>();
    public availabilities = new Array<string>();
    public sourceFilterParameters = new Array<string>();
    public manufacturers = new Array<string>();
    public categoryFilterParameters = new Array<string>();
    public groupFilterParameters = new Array<FilterParameter>();

    constructor(
        phrase = "",
        groupFilterParameters: FilterParameter[] = [],
        categoryFilterParameters: string[] = [],
        offset = 1,
        resultsPerPage = 10,
        tags: string[] = [],
        catcept = "",
        availabilities: string[] = [],
        source = "",
        sourceFilterParameters: string[] = [],
        manufacturers: string[] = [],
    ) {
        this.tags = tags;
        this.offset = offset;
        this.source = source;
        this.phrase = phrase;
        this.catceptId = catcept;
        this.availabilities = availabilities;
        this.resultsPerPage = resultsPerPage;
        this.groupFilterParameters = groupFilterParameters;
        this.sourceFilterParameters = sourceFilterParameters;
        this.manufacturers = manufacturers;
        this.categoryFilterParameters = categoryFilterParameters;
    }

    public updateGroupFilter(filterParameter: FilterParameter, isActive: boolean): void {
        if (isActive) {
            this.groupFilterParameters.push({
                key: filterParameter.key,
                value: filterParameter.value
            });
        } else {
            for (let idx in this.groupFilterParameters) {
                let item = this.groupFilterParameters[idx];
                if (
                    item.key === filterParameter.key &&
                    item.value === filterParameter.value
                ) {
                    if (isActive === false)
                        this.groupFilterParameters.splice(parseInt(idx), 1);
                }
            }
        }
    }

    public updateCategoryFilter(category: string, isActive: boolean): void {
        if (isActive) {
            this.categoryFilterParameters.push(category);
        } else {
            var idx = this.categoryFilterParameters.findIndex(c => c === category);
            this.categoryFilterParameters.splice(idx, 1);
        }
    }

    public updateSourceFilter(source: string, isActive: boolean): void {
        if (isActive) {
            this.sourceFilterParameters.push(source);
        } else {
            var idx = this.sourceFilterParameters.findIndex(c => c === source);
            this.sourceFilterParameters.splice(idx, 1);
        }
    }

    public updateManufacturerFilter(manufacturer: string, isActive: boolean): void {
        if (isActive) {
            this.manufacturers.push(manufacturer);
        } else {
            var idx = this.manufacturers.findIndex(c => c === manufacturer);
            this.manufacturers.splice(idx, 1);
        }
    }


    public updateAvailabilityFilter(source: string, isActive: boolean): void {
        if (isActive) {
            this.availabilities.push(source);
        } else {
            var idx = this.availabilities.findIndex(c => c === source);
            this.availabilities.splice(idx, 1);
        }
    }

    public updateTagFilter(tag: string, isActive: boolean): void {
        if (isActive) {
            this.tags.push(tag);
        } else {
            var idx = this.tags.findIndex(t => t === tag);
            this.tags.splice(idx, 1);
        }
    }

    public clearFilters(): void {
        this.tags = [];
        this.groupFilterParameters = [];
        this.sourceFilterParameters = [];
        this.manufacturers = [];
        this.categoryFilterParameters = [];
    }
}