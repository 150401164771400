import { createState, useState } from '@hookstate/core';
import { MyProductList } from '../models';

const listState: any = createState(null);
const stateWrapper = (state: any) => ({
    get: (): MyProductList[] => {
        return state?.value
    },
    set: (myProductLists: MyProductList[]) => {
        return state?.set((pl: MyProductList[]) => pl = myProductLists);
    },
    getById: (id: string): MyProductList => {
        return state?.value?.find((pl: MyProductList) => pl.id === id);
    },
    getByType: (type: string): MyProductList[] => {
        if (!state.value) return []
        return state.value.filter(
            (productList: MyProductList) => productList.type === type
        );
    },
    removeById: (id: string): MyProductList => {
        return state?.set((pl: MyProductList[]) => {
            if (!pl || !id)
                return pl;

            const foundItemIdx = pl.findIndex(i => i.id === id);
            if (foundItemIdx >= 0)
                pl.splice(foundItemIdx, 1);

            return pl;
        });
    },
    setList: (productList: MyProductList) => {
        return state?.set((pl: MyProductList[]) => {
            if (!pl)
                return productList ? [productList] : [];

            const foundItemIdx = pl.findIndex(i => i.id === productList.id);
            if (foundItemIdx < 0)
                pl.push(productList);
            else
                pl[foundItemIdx] = productList;

            return pl;
        });
    }
});

export const accessMyProductLists = () => stateWrapper(listState);
export const useMyProductLists = () => stateWrapper(useState(listState));