import settings from '../settings';
import CartService from './CartService';
import UserStorage from '../utils/userStorage';
import { Address } from '../models/Address/Address';
import { Npi, Permission, User, UserAttributeDto } from '../models';
import { UserSegment } from '../models/User/UserSegment';
import ErrorHandlingService from './ErrorHandlingService';
import MyProductListService from './MyProductListService';
import UserRepository from '../repositories/UserRepository';
import { accessUser } from '../stores/userStore';
import { UserList } from '../models/User/UserList';

class UserService {
    static async login(email: string, password: string): Promise<string> {
        const userToken = await UserRepository.login(email, password)
            .catch(e => { throw ErrorHandlingService.getMessages(e); });

        UserStorage.setToken(userToken);

        await CartService
            .transferCartFromGuestToCustomer()
            .catch(async () => {
                console.warn(
                    "TransferCartFromGuestToCustomer failed, " +
                    "creating new Cart..."
                );
                await CartService.create();
            });

        await MyProductListService.list();

        return userToken;
    }

    static async ghostLogin(email: string): Promise<string> {
        const adminToken = UserStorage.getToken();
        const userToken = await UserRepository.ghostLogin(email, adminToken)
            .catch(e => { throw ErrorHandlingService.getMessages(e); });

        UserStorage.setGhostToken(userToken);

        await CartService.create();

        return userToken;
    }

    static async getByToken(externalToken?: string, noCache: boolean = false): Promise<User> {
        try {
            let user: any = accessUser().get();
            if (user && !noCache) {
                return new User(user);
            }
            const token = externalToken || UserStorage.getToken();
            user = await UserRepository.getByToken(token);
            accessUser().set(Object.assign({}, user))
            return user;
        } catch (e) {
            var messages = ErrorHandlingService.getMessages(e);
            // TODO: This must be m.code, fix it later
            if (messages.some(m => m.message === "USER_NOT_FOUND"))
                UserService.logout();

            throw messages;
        }
    }

    static async getBySlug(slug: string): Promise<User> {
        try {
            return await UserRepository.getBySlug(slug);
        } catch (e) {
            var messages = ErrorHandlingService.getMessages(e);
            if (messages.some(m => m.code === "USER_NOT_FOUND"))
                UserService.logout();

            throw messages;
        }
    }

    static async getCompany(): Promise<User|null> {
        try {
            const token = UserStorage.getToken();
            const user = UserStorage.get()
            if (!user) return null
            return await UserRepository.getAscendent(user.id, 'customer-company', token)
        } catch (e) {
            return null
        }        
    }

    static async emailExists(email: string): Promise<boolean> {
        try {
            return await UserRepository.emailExists(email);
        } catch (e) {
            var messages = ErrorHandlingService.getMessages(e);
            if (messages.some(m => m.code === "CUSTOMER_NOT_FOUND"))
                UserService.logout();

            throw messages;
        }
    }

    static async listUserSegments(): Promise<UserSegment[]> {
        try {
            return await UserRepository.listUserSegments();
        } catch (e) {
            var messages = ErrorHandlingService.getMessages(e);
            if (messages.some(m => m.code === "CUSTOMER_NOT_FOUND"))
                UserService.logout();

            throw messages;
        }
    }

    static async add(user: User, forceChangePassword = false, createSlug = false): Promise<User> {
        let groupCode = settings.env.REACT_APP_DEFAULT_GROUP_CODE
        for (const userAttribute of user?.userAttributes) {
            if (userAttribute.attributeGroupCode === 'personal-information' && userAttribute.attributeCode === 'organization') {
                groupCode = 'healthcare-professional';
                break;
            }
        }
            
        try {
            const token = UserStorage.getToken();
            return await UserRepository.add(
                user,
                forceChangePassword,
                createSlug,
                groupCode,
                token
            );
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async update(user: User): Promise<User> {
        try {
            const token = UserStorage.getToken();
            return await UserRepository.update(user, token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async updateUserAttribute(attributes: UserAttributeDto[]) {
        try {
            const token = UserStorage.getToken();
            return await UserRepository.updateUserAttributes(attributes, token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async list(search = "", pagination?: { page: number, limit: number }): Promise<UserList> {
        try {
            const token = UserStorage.getToken();
            return await UserRepository.list(search, token, pagination);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async listDescendents(): Promise<User[]> {
        try {
            const token = UserStorage.getToken();
            const myId = UserStorage.get()?.id || "";
            return await UserRepository.listDescendents(myId, token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async listSponsoreds(): Promise<User[]> {
        try {
            const token = UserStorage.getToken();
            const myId = UserStorage.get()?.id || "";
            return await UserRepository.listSponsored(myId, token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async changePassword(
        currentPassword: string,
        newPassword: string
    ): Promise<boolean> {
        const token = UserStorage.getToken();
        try {
            return await UserRepository
                .changePassword(currentPassword, newPassword, token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async delete(userId: string): Promise<User> {
        try {
            const token = UserStorage.getToken();
            return await UserRepository.delete(userId, token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async ghostLogout(): Promise<void> {
        UserStorage.ghostLogout();
        await CartService.create();
    }

    static async logout(): Promise<void> {
        UserStorage.logout();
        await CartService.create();
    }

    static async saveAddress(addressToSave: Address, userId?: string): Promise<Address> {
        const token = UserStorage.getToken();
        try {
            await UserRepository.normalizeAddress(addressToSave, token)
            return await UserRepository.saveAddress(addressToSave, token, userId);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async deleteAddress(address: Address): Promise<void> {
        const token = UserStorage.getToken();
        try {
            await UserRepository.deleteAddress(address, token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async npiMatchAddress(address: Address): Promise<Npi[]> {
        const token = UserStorage.getToken();

        try {
            return await UserRepository.npiMatchAddress(address, token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async proAddressVerification(npiId: string): Promise<boolean> {
        const token = UserStorage.getToken();

        try {
            return await UserRepository.proAddressVerification(npiId, token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async unsetDefaultBillingAddress(addresses: Address[]): Promise<void> {
        const addressOld = Address.getBillingAddress(addresses);

        if (addressOld.id !== '') {
            addressOld.defaultBilling = false;
            await UserService.saveAddress(addressOld)
                .catch(e => { throw ErrorHandlingService.getMessages(e); });
        }
    }

    static async unsetDefaultShippingAddress(addresses: Address[]): Promise<void> {
        const addressOld = Address.getShippingAddress(addresses);

        if (addressOld.id !== '') {
            addressOld.defaultShipping = false;
            await UserService.saveAddress(addressOld)
                .catch(e => { throw ErrorHandlingService.getMessages(e); });
        }
    }

    static async setDefaultBillingAddress(
        addresses: Address[],
        addressNew: Address
    ): Promise<User> {
        await UserService.unsetDefaultBillingAddress(addresses);

        addressNew.defaultBilling = true;
        await UserService.saveAddress(addressNew)
            .catch(e => { throw ErrorHandlingService.getMessages(e); });

        return await UserService.updateStorage();
    }

    static async setDefaultShippingAddress(
        addresses: Address[],
        addressNew: Address
    ): Promise<User> {
        await UserService.unsetDefaultShippingAddress(addresses);

        addressNew.defaultShipping = true;
        await UserService.saveAddress(addressNew)
            .catch(e => { throw ErrorHandlingService.getMessages(e); });

        return await UserService.updateStorage();
    }

    static async updateStorage(): Promise<User> {
        const token = UserStorage.getToken();
        const user = await UserService.getByToken(token);

        return user;
    }

    static async sendResetPasswordToken(email: string): Promise<boolean> {
        try {
            return await UserRepository.sendResetPasswordToken(email);
        } catch (e) {
            var messages = ErrorHandlingService.getMessages(e);
            throw messages;
        }
    }

    static async resetPassword(
        email: string,
        resetToken: string,
        newPassword: string
    ): Promise<boolean> {
        try {
            return await UserRepository.resetPassword(
                email, resetToken, newPassword
            );
        } catch (e) {
            var messages = ErrorHandlingService.getMessages(e);
            throw messages;
        }
    }

    static async changeNewsletterSubscription(isSubscribed: boolean): Promise<boolean> {
        const token = UserStorage.getToken();
        try {
            return await UserRepository
                .changeNewsletterSubscription(isSubscribed, token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async getPermissions(): Promise<Permission[] > {
        try {
            const token = UserStorage.getToken();
            const user = await UserService.getByToken(token);
            return user.role.permissions;
        } catch (e) {
            var messages = ErrorHandlingService.getMessages(e);
            if (messages.some(m => m.code === "USER_NOT_FOUND"))
                UserService.logout();
            throw messages;
        }
    }

    static async hasPermission(code: string): Promise<boolean> {
        try {
            const permissions = await UserService.getPermissions();
            const idx = permissions.findIndex(permission => permission.code === code);
            return idx >= 0;
        } catch (e) {
            var messages = ErrorHandlingService.getMessages(e);
            if (messages.some(m => m.code === "USER_NOT_FOUND"))
                UserService.logout();
            throw messages;
        }
    }

    static async becomeCompany(): Promise<void> {
        try {
            const token = UserStorage.getToken();
            const userToken = await UserRepository.becomeCompany(token);
            UserStorage.setToken(userToken);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async getById(userId: string): Promise<User> {
        try {

            const token = UserStorage.getToken();
            return await UserRepository.getById(userId, token);;
        } catch (e) {
            var messages = ErrorHandlingService.getMessages(e);
            // TODO: This must be m.code, fix it later
            if (messages.some(m => m.message === "USER_NOT_FOUND"))
                UserService.logout();

            throw messages;
        }
    }
}

export default UserService;