import { User } from "../User/User";
import { MyProductListItem } from "./MyProductListItem";

export class MyProductList {
    public id?: string;
    public name: string;
    public slug: string;
    public type: string;
    public status: string;
    public customerId: number;
    public createdAt = new Date();
    public updatedAt = new Date();
    public requestedReviewAt = new Date();
    public items: MyProductListItem[] = [];
    public owner: User = new User();

    constructor(data: any = {}) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.customerId = data.customerId;
        try {
            if (data.createdAt) {
                const dateStr = typeof data.createdAt === 'string' 
                    ? data.createdAt.endsWith('Z') 
                        ? data.createdAt 
                        : data.createdAt + 'Z'
                    : data.createdAt;
                this.createdAt = data.createdAt instanceof Date 
                    ? data.createdAt 
                    : new Date(dateStr);
            }
            
            if (data.updatedAt) {
                const dateStr = typeof data.updatedAt === 'string'
                    ? data.updatedAt.endsWith('Z')
                        ? data.updatedAt
                        : data.updatedAt + 'Z'
                    : data.updatedAt;
                this.updatedAt = data.updatedAt instanceof Date
                    ? data.updatedAt
                    : new Date(dateStr);
            }
            
            if (data.requestedReviewAt) {
                const dateStr = typeof data.requestedReviewAt === 'string'
                    ? data.requestedReviewAt.endsWith('Z')
                        ? data.requestedReviewAt
                        : data.requestedReviewAt + 'Z'
                    : data.requestedReviewAt;
                this.requestedReviewAt = data.requestedReviewAt instanceof Date
                    ? data.requestedReviewAt
                    : new Date(dateStr);
            }
        } catch (e) {
            console.warn('Date conversion failed, using current date', e);
            this.createdAt = new Date();
            this.updatedAt = new Date();
            this.requestedReviewAt = new Date();
        }
        
        this.type = data.type || 'shopping';
        this.status = data.status || 'open';
        this.owner = new User(data.owner);
        data.items?.forEach((item: any) => 
            this.items.push(new MyProductListItem(item))
        );
    }
}