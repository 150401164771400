import { Invoice } from "../Invoice/Invoice";
import { PurchaseOrderItem } from "./PurchaseOrderItem";
import { PurchaseOrderTracking } from "./PurchaseOrderTracking";

export class PurchaseOrder {
    public id = 0;
    public orderId = 0;
    public purchaseOrderNumber: string;
    public needsAttention = false;
    public createdAt = new Date();
    public processedAt = new Date();
    public source = '';
    public sourceOrderNumber = '';
    public status = '';
    public invoiceStatus = '';
    public total = 0;
    public qtyOrdered = 0;
    public qtyShipped = 0;
    public qtyBackordered = 0;
    public auditMessage = '';
    public poNumber = '';

    public items: PurchaseOrderItem[] = [] 
    
    public trackings: PurchaseOrderTracking[] = []

    public invoices: Invoice[] = [];

    constructor(data: any = {}) {
        this.orderId = data.orderId;
        this.source = data.source;
        this.sourceOrderNumber = data.sourceOrderNumber;
        this.status = data.status;
        this.invoiceStatus = data.invoiceStatus;
        this.total = data.total || 0;
        this.id = parseInt(data.id || 0);
        this.qtyOrdered = parseInt(data.qtyOrdered) || 0;
        this.qtyShipped = data.qtyShipped || 0;
        this.createdAt = data.createdAt ? new Date(data.createdAt) : new Date();
        this.processedAt = data.processedAt ? new Date(data.processedAt) : new Date();
        this.purchaseOrderNumber = data.orderNumber;
        this.qtyBackordered = data.qtyBackordered || 0;
        this.needsAttention = data.needsAttention ? true : false;

        this.auditMessage = data.auditMessage;

        this.poNumber = data.poNumber;

        if (data.items) {
            for (const itemData of data.items) {
                this.items.push(new PurchaseOrderItem(itemData));
            }
        }  
        
        if (data.tracking) {
            for (const item of data.tracking) {
                this.trackings.push(new PurchaseOrderTracking(item));
            }
        }
        
        if (data.invoices) {
            for (const invoiceData of data.invoices) {
                console.log(invoiceData);
                this.invoices.push(new Invoice(invoiceData));
            }
        }  
    }

    public getQtyShipped() {
        let qty = 0;
        for (const item of this.items) {
            qty += item.qtyShipped
        }
        return qty;
    }
}