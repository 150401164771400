import {
    SearchParameter,
    Product
} from '../models';
import UserStorage from '../utils/userStorage';
import ErrorHandlingService from './ErrorHandlingService';
import ProductRepository from '../repositories/ProductRepository';
import { ReactiveProduct } from '../models/Product/ReactiveProduct';
import { accessReactiveProducts } from '../stores/reactiveProductStore';
import { ProductSearchResult } from '../models/Product/ProductSearchResult';
import { ProductTag } from '../models/Product/ProductTag';

export default class ProductService {
    static async getSample(): Promise<Product[]> {
        try {
            const token = UserStorage.getToken();
            const products = await ProductRepository.getSampleProducts(token);
            products.filter(p => p.isUpdateInProgress)
                .forEach(p => ProductService.subscribeTo(p.toReactive()));
            return products;
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async typeahead(params: SearchParameter): Promise<Product[]> {
        try {
            const token = UserStorage.getToken();
            const products = await ProductRepository.typeahead(params, token);
            products.filter(p => p.isUpdateInProgress)
                .forEach(p => ProductService.subscribeTo(p.toReactive()));
            return products;
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async search(params: SearchParameter): Promise<ProductSearchResult> {
        try {
            const token = UserStorage.getToken();
            const searchResult = await ProductRepository.search(params, token);

            const groups = searchResult.parameterGroups;
            const brandIndex = groups.findIndex(c => c.name === "Brand");
            if (brandIndex >= 0) {
                const brandGroup = groups.splice(brandIndex, 1)[0];
                groups.unshift(brandGroup);
            }

            const manufacturerIndex = groups.findIndex(c => c.name === "Manufacturer");
            if (manufacturerIndex >= 0) {
                const manufacturerGroup = groups.splice(manufacturerIndex, 1)[0];
                groups.unshift(manufacturerGroup);
            }

            searchResult.parameterGroups = groups;

            searchResult.products.filter(p => p.isUpdateInProgress)
                .forEach(p => ProductService.subscribeTo(p.toReactive()));
            return searchResult;
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async getById(id: string): Promise<Product> {
        try {
            const token = UserStorage.getToken();
            const product = await ProductRepository.getProductById(id, token);
            if (product.isUpdateInProgress)
                ProductService.subscribeTo(product.toReactive());
            return product;
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async getByIds(ids: string[]): Promise<Product[]> {
        try {
            const token = UserStorage.getToken();
            const products = await ProductRepository.getProductsById(ids, token);
            products.filter(p => p.isUpdateInProgress)
                .forEach(p => ProductService.subscribeTo(p.toReactive()));
            return products;
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static subscribeTo(rxProduct: ReactiveProduct): void {
        try {
            ProductRepository.subscribeTo(rxProduct.updateKey);
            const rxProducts = accessReactiveProducts();
            rxProducts.add(rxProduct);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async getTags(): Promise<ProductTag[]> {
        try {
            const token = UserStorage.getToken();
            const tags = await ProductRepository.getTags(token);
            return tags;
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }

    static async productsAddedToCart(id: string): Promise<boolean> {
        try {
            const token = UserStorage.getToken();
            return await ProductRepository.productsAddedToCart(id, token);
        } catch (e) {
            throw ErrorHandlingService.getMessages(e);
        }
    }
}