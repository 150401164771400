export class InvoiceItem {
    public type = '';
    public description = '';
    public sourceId = '';
    public sourceSku = '';
    public qty = 0;
    public price = 0;
    public total = 0;

    constructor (data: any = {}) {
        this.type = data.type;
        this.description = data.description;
        this.sourceId = data.sourceId;
        this.sourceSku = data.sourceSku;
        this.qty = data.qty;
        this.price = parseFloat(data.price);
        this.total = parseFloat(data.total);
    }

}