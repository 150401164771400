import { Product } from "..";

export enum CartItemDiffAction {
    UNAVAILABLE = "UNAVAILABLE",
    PRICE_CHANGE = "PRICE_CHANGE"
}

export class CartItemDiff {
    public sku = 0;
    public oldPrice = 0;
    public newPrice = 0;
    public product:Product;
    public action:CartItemDiffAction;

    constructor(data: any = {}) {
        this.sku = data.sku;
        this.oldPrice = data.oldPrice;
        this.newPrice = data.newPrice;
        this.product =  data.item instanceof Product ? 
            data.item : new Product({...data.item, gallery: [data.image]});
        this.action = 
            CartItemDiffAction[data.action as keyof typeof CartItemDiffAction];
    }
}