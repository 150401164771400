import { PurchaseOrder } from "../PurchaseOrder/PurchaseOrder";
import { OrderCoupon } from "./OrderCoupon";
import { OrderFraud } from "./OrderFraud";
import { OrderHistory } from "./OrderHistory";
import { OrderHoldItem } from "./OrderHoldItem";
import { OrderItem } from "./OrderItem"
import { OrderNote } from "./OrderNote";
import { OrderPayment } from "./OrderPayment";
import { OrderShipping } from "./OrderShipping";
import { OrderTotals } from "./OrderTotals";

export class Order {
    public id = 0;
    public processInstanceId = '';
    public startDate = new Date();
    public channel = '';
    public originId = '';
    public originRef = '';
    public originUserId = '';
    public originCompanyId = '';
    public email = '';

    public totals = new OrderTotals();
    public shipping = new OrderShipping();
    public payment = new OrderPayment();

    public holdItems: OrderHoldItem[] = [];

    public fraud: OrderFraud = new OrderFraud();

    public coupons: OrderCoupon[] = [];

    public orderStatus = '';
    public needsAttention = false;
    public processInstanceState = '';
    public processVersion = '';

    public items: OrderItem[] = [];

    public purchaseOrders: PurchaseOrder[] = []

    public histories: OrderHistory[] = [];

    public notes: OrderNote[] = [];

    constructor(data: any = {}) {
        this.id = data.id;

        this.startDate = new Date(data.startDate);
        this.originUserId = data.originUserId;
        this.email = data.email;

        if (data.totals) {
            this.totals = new OrderTotals(data.totals);
        }

        if (data.shipping) {
            this.shipping = new OrderShipping(data.shipping);
        }

        if (data.payment) {
            this.payment = new OrderPayment(data.payment);
        }

        // TODO: Take a look why this exists
        // if (data.holdItems) {
        //     for (const holdItem of data.holdItems) {
        //         this.holdItems.push(new OrderHoldItem(holdItem))
        //     }
        // }

        // TODO: Take a look why this exists
        if (data.fraud) {
            this.fraud = new OrderFraud(data.fraud);
        }

        // TODO: Ignore this for now
        // if (data.coupons) {
        //     for (const coupon of data.coupons) {
        //         this.coupons.push(new OrderCoupon(coupon))
        //     }
        // }

        if (data.items) {
            for (const item of data.items) {
                this.items.push(new OrderItem(item))
            }
        }

        this.orderStatus = data.orderStatus;
        this.needsAttention = data.needsAttention;
        this.processInstanceState = data.processInstanceState;
        this.processVersion = data.processVersion

        // TODO: Ignored this for now
        // if (data.purchaseOrders) {
        //     for (const pruchaseOrder of data.purchaseOrders) {
        //         this.purchaseOrders.push(new PurchaseOrder(pruchaseOrder));
        //     }
        // }

        if (data.histories) {
            for (const historyData of data.histories) {
                this.histories.push(new OrderHistory(historyData));
            }
        }

        if (data.notes) {
            for (const note of data.notes) {
                this.notes.push(new OrderNote(note));
            }
        }
    }
}
