export class Term {
    public label = '';
    public value = '';
    public kind = '';
   

    constructor(data:any = null) {
        this.label = data.label;
        this.value = data.value;
        this.kind = data.kind;        
    }
}