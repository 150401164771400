import UserStorage from "../../utils/userStorage";
import React, { useEffect, useState } from "react";
import { useFlash } from "../../stores/flashStore";
import UserService from "../../services/UserService";
import { useDialog } from "../../stores/dialogStore";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import ProductListsMenu from "./submenus/product-lists";
import ManageOrdersMenu from "./submenus/manage-orders";
import BusinessSettingsMenu from "./submenus/business-settings";
import { useMyProductLists } from "../../stores/myProductListStore";
import MyProductListService from "../../services/MyProductListService";

import userIcon from '../../assets/images/icons/user.svg';
import usersIcon from '../../assets/images/icons/users.svg';
import logoutIcon from '../../assets/images/icons/logout.svg';
import walletIcon from '../../assets/images/icons/wallet.svg';
// import quotesIcon from '../../assets/images/icons/quotes.svg';
import addressesIcon from '../../assets/images/icons/addresses.svg';
import listsIcon from '../../assets/images/icons/product-lists.svg';
import businessIcon from '../../assets/images/icons/business-settings.svg';
import manageOrdersIcon from '../../assets/images/icons/manage-orders.svg';
import './styles.scss';
import ManageCustomersMenu from "./submenus/manage-customers";

export enum SubMenuType {
    ProductLists = "ProductLists",
    ManageOrders = "ManageOrders",
    BusinessSettings = "BusinessSettings",
    ManageCustomers = "ManageCustomers",
}

interface PropTypes {
    title?: string;
    subtitle?: string;
    submenu?: SubMenuType;
}

export default function AccountLeftMenu(props: PropTypes) {
    const flash = useFlash();
    const dialog = useDialog();
    const history = useHistory();
    const currentUser = UserStorage.get();
    const location = useLocation();

    const path = history.location.pathname;
    const productListsState = useMyProductLists();
    const isGhostProfile = UserStorage.hasGhostToken();
    const isCompanyAdministrativeProfile = UserStorage.isCompany() || 
        UserStorage.isCompanyManager();
    const isRepresentative = UserStorage.get()?.getAttributeValue('rep');

    const [title, setTitle] = useState(props.title);
    const [subMenu, setSubMenu] = useState(props.submenu);
    const [subtitle, setSubtitle] = useState(props.subtitle);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get("userId");

    useEffect(() => {
        if (productListsState.get())
            return;

        MyProductListService.list();        
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (path.includes("my-account/orders"))
            openSubMenu(SubMenuType.ManageOrders);
        else if (path.includes("my-account/product-list"))
            openSubMenu(SubMenuType.ProductLists);
        else if (path.includes("my-account/business-settings"))
            openSubMenu(SubMenuType.BusinessSettings);
        else if (
            path.includes("my-account/manage-customers") || 
            (
                (path.includes("my-account/addresses/") || path.includes("my-account/addresses")) && 
                userId
            )
        )
            openSubMenu(SubMenuType.ManageCustomers);
        // eslint-disable-next-line
    }, [history.location]);

    const toggleMenu = () => setMobileMenuOpen(!mobileMenuOpen);

    const getGhostUsername = ():string => {
        let firstName = currentUser?.getAttributeValue("firstname");

        if (!firstName)
            firstName = currentUser?.email || "";
        
        return firstName + "'s";
    };

    const onLogout = () => {
        dialog.show({
            type: "confirm",
            title: "Logging out",
            message: "Are you sure you want to log out?"
        }).then(async (result) => {
            if (!result)
                return;

            history.push("/");
            await UserService.logout();  // TODO: Fix cart api
        });        
    };

    const onGhostLogout = async () => {
        await UserService.ghostLogout();
        flash.show({
            type: "success",
            message: "Logged out successfully",
        });
        setTimeout(() => {
            window.location.href = "/";
        }, 2000)
    };

    const dismissSubMenu = (event: any) => {
        if (!subMenu)
            return;

        event.currentTarget.classList.add("active");
        
        setSubMenu(undefined);
        setTitle(props.title);
        setSubtitle(props.subtitle);
    };

    const openSubMenu = (type: SubMenuType) => {
        setSubMenu(type);
        let subMenuSelector = "";
        if (type === SubMenuType.ManageOrders) {
            subMenuSelector = "manage-orders";
            setTitle("Manage Orders");
            setSubtitle("Track and Reorder Supplies");
        } else if (type === SubMenuType.ProductLists) {
            subMenuSelector = "product-list";
            setTitle("Product Lists");
            setSubtitle("Manage reorder and preferred product lists");
        } else if (type === SubMenuType.BusinessSettings) {
            subMenuSelector = "business-settings";
            setTitle("Business Settings");
            setSubtitle("Configure and manage your business");
        } else if (type === SubMenuType.ManageCustomers) {
            subMenuSelector = "manage-customers";
            setTitle("Manage Customers");
            setSubtitle("Manage the system customers");
        }

        document
            .querySelectorAll(".main-menu > .link.active:not(.submenu-link)")
            .forEach(m => m.classList.remove("active"));

        document
            .querySelectorAll(".sub-menu.active")
            .forEach(m => m.classList.remove("active"));
        
        document.querySelector(`.sub-menu.${subMenuSelector}`)?.
            classList.add("active");
        
        onMouseLeaveMenuItem();
    };

    const onMouseEnterMenuItem = () => {
        document.querySelector(".left-menu-container .main-menu")?.
            classList.add("hover");
    };

    const onMouseLeaveMenuItem = () => {
        document.querySelector(".left-menu-container .main-menu")?.
            classList.remove("hover");
    };

    const hasPaymentAccess = (): boolean => {
        if (!UserStorage.isCompanyBuyer() || !UserStorage.isCompanyManager())
            return true;

        return currentUser
            ?.getAttributeValue("shared-settings-payment-methods") !== "shared";
    };

    return (
        <>
            <button 
                onClick={toggleMenu}
                className="mobile-only"
                id="account-menu-mobile" 
            >
                <img src={userIcon} alt="my account" />
            </button>
            <div className={
                "left-menu" + 
                (!subMenu ? " root" : " submenu") +
                (mobileMenuOpen ? " open" : "")
            }>
                <div className="left-menu-container">
                    <div className="title">{title || "Account Details"}</div>
                    {subtitle &&
                        <div className="subtitle">{subtitle}</div>
                    }
                    <button 
                        onClick={toggleMenu}
                        className="close-btn mobile-only"
                    >
                        X
                    </button>
                    <div className="menus">
                        <nav className="main-menu">
                            <NavLink 
                                exact 
                                to="/my-account"
                                className="link" 
                                activeClassName="active" 
                                onClick={dismissSubMenu}
                                onMouseEnter={onMouseEnterMenuItem}
                                onMouseLeave={onMouseLeaveMenuItem}
                            >
                                <img src={userIcon} alt="account" title="Account" />
                                <span>
                                    {isGhostProfile 
                                        ? getGhostUsername()
                                        : ""
                                    } Account Details
                                </span>
                            </NavLink>
                            <button 
                                className={
                                    "link submenu-link " + 
                                    (subMenu === SubMenuType.ManageOrders ||
                                        path.includes("my-account/orders")
                                        ? "active" : ""
                                    )
                                }
                                onMouseEnter={onMouseEnterMenuItem}
                                onMouseLeave={onMouseLeaveMenuItem}
                                onClick={() => openSubMenu(SubMenuType.ManageOrders)}
                            >
                                <img src={manageOrdersIcon} alt="orders" title="Manage Orders" />
                                <span>
                                    {isGhostProfile ?
                                        getGhostUsername() : "Manage"
                                    } Orders
                                </span>
                            </button>
                            {UserStorage.isMaster() && <button
                                className={
                                    "link submenu-link " + 
                                    (subMenu === SubMenuType.ManageCustomers ||
                                        path.includes("my-account/manage-customers")
                                        ? "active" : ""
                                    )
                                }
                                onMouseEnter={onMouseEnterMenuItem}
                                onMouseLeave={onMouseLeaveMenuItem}
                                onClick={() => openSubMenu(SubMenuType.ManageCustomers)}
                            >
                                <img src={usersIcon} alt="Manage Customers" title="Manage Customers" />
                                <span>Manage Customers</span>
                            </button>}
                            {/* <NavLink 
                                className="link d-none" 
                                to="/my-account/quotes"
                                activeClassName="active" 
                                onClick={dismissSubMenu}
                                onMouseEnter={onMouseEnterMenuItem}
                                onMouseLeave={onMouseLeaveMenuItem}
                            >
                                <img src={quotesIcon} alt="quotes" title="Quotes" />
                                <span>
                                    {isGhostProfile ?
                                        getGhostUsername() : "Manage"
                                    } Quotes
                                </span>
                            </NavLink> */}
                            {isRepresentative &&
                                <NavLink 
                                    className="link" 
                                    activeClassName="active" 
                                    onClick={dismissSubMenu}
                                    to="/my-account/sales-accounts"
                                    onMouseEnter={onMouseEnterMenuItem}
                                    onMouseLeave={onMouseLeaveMenuItem}
                                >
                                    <span>Sales Accounts</span>
                                </NavLink>
                            }
                            <button
                                className={
                                    "link submenu-link " + 
                                    (subMenu === SubMenuType.ProductLists ||
                                        path.includes("my-account/product-list")
                                        ? "active" : ""
                                    )
                                }
                                onMouseEnter={onMouseEnterMenuItem}
                                onMouseLeave={onMouseLeaveMenuItem}
                                onClick={() => openSubMenu(SubMenuType.ProductLists)}
                            >
                                <img src={listsIcon} alt="product lists" title="Product Lists" />
                                <span>Product Lists</span>
                            </button>
                            <NavLink 
                                className="link" 
                                activeClassName="active" 
                                onClick={dismissSubMenu}
                                to="/my-account/addresses"
                                onMouseEnter={onMouseEnterMenuItem}
                                onMouseLeave={onMouseLeaveMenuItem}
                            >
                                <img src={addressesIcon} alt="addresses" title="Address Book" />
                                <span>Address Book</span>
                            </NavLink>
                            {hasPaymentAccess() &&
                                <NavLink 
                                    className="link" 
                                    activeClassName="active" 
                                    onClick={dismissSubMenu}
                                    to="/my-account/payment-methods"
                                    onMouseEnter={onMouseEnterMenuItem}
                                    onMouseLeave={onMouseLeaveMenuItem}
                                >
                                    <img src={walletIcon} alt="wallet" title="Payment Methods" />
                                    <span>Payment Methods</span>
                                </NavLink>
                            }
                            {isCompanyAdministrativeProfile &&
                                <button
                                    className={
                                        "link submenu-link " + 
                                        (subMenu === SubMenuType.BusinessSettings ||
                                            path.includes("my-account/business-settings")
                                            ? "active" : ""
                                        )
                                    }
                                    onMouseEnter={onMouseEnterMenuItem}
                                    onMouseLeave={onMouseLeaveMenuItem}
                                    onClick={() => openSubMenu(SubMenuType.BusinessSettings)}
                                >
                                    <img src={businessIcon} alt="business settings" title="Business Settings" />
                                    <span>Business Settings</span>
                                </button>
                            }
                            <button
                                className="link"
                                onMouseEnter={onMouseEnterMenuItem}
                                onMouseLeave={onMouseLeaveMenuItem}
                                onClick={
                                    isGhostProfile ? onGhostLogout : onLogout
                                }
                            >
                                <img src={logoutIcon} alt="logout" title="Logout" />
                                <span>{isGhostProfile && "Customer "} Logout</span>
                            </button>
                        </nav>
                        <nav className="sub-menu manage-orders">
                            <ManageOrdersMenu onItemSelect={toggleMenu} />
                        </nav>
                        <nav className="sub-menu product-list">
                            <ProductListsMenu onItemSelect={toggleMenu} />
                        </nav>
                        <nav className="sub-menu business-settings">
                            <BusinessSettingsMenu onItemSelect={toggleMenu} />
                        </nav>
                        {UserStorage.isMaster() && <nav className="sub-menu manage-customers">
                            <ManageCustomersMenu onItemSelect={toggleMenu} />
                        </nav>}
                    </div>
                </div>
            </div>
        </>
    );
}