export class PurchaseOrderTracking {
    public carrierCode = '';
    public serviceCode = '';
    public trackingNumber = '';

    constructor (data: any = {}) {
        this.carrierCode = data.carrierCode;
        this.serviceCode = data.serviceCode;
        this.trackingNumber = data.trackingNumber;
    }

}