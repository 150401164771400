export class Permission {
    public id = '';
    public name = '';
    public code = '';
    public position = 0;

    constructor(data: any = {}) {
        this.id = data.id;
        this.name = data.name;
        this.code = data.code;
        this.position = parseInt(data.position);
    }
}
