import { Parameter } from "./Parameter";

export class ParameterGroup {
    public name = '';
    public totalCount = 0;
    public parameters:Parameter[];

    constructor (data :any) {
        this.name = data.name;
        this.totalCount = data.totalCount;
        
        this.parameters = new Array<Parameter>();
        data.parameters.forEach((parameter:any) =>
            this.parameters.push(new Parameter(parameter.value, parameter.count))
        );
    }

    public static createArray(data:any):ParameterGroup[] {
        let parameterGroups = new Array<ParameterGroup>();
        data.forEach((parameterGroup:any) =>
            parameterGroups.push(new ParameterGroup(parameterGroup))
        );

        return parameterGroups;
    }
}